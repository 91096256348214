import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";

import { AutomationService } from "../automation.service";
import { TaskSet } from "../automation";

@Injectable({
    providedIn: "root"
})
export class AutomationListResolverService implements Resolve<TaskSet[]> {
    constructor(private as: AutomationService) {}

    resolve(): Observable<TaskSet[]> | Observable<never> {
        return new Observable((observe: Subscriber<TaskSet[]>) => {
            this.as.refreshTaskSets().subscribe((taskSets: TaskSet[]) => {
                observe.next(taskSets);
                observe.complete();
            });
        });
    }
}
