import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../modules/shared/shared.module";
import { MatMenuModule } from "@angular/material/menu";

// Forms
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

// Components
import { AutomationComponent } from "./automation/automation.component";
import { AutomationFormComponent } from "./automation-form/automation-form.component";
import { AutomationListComponent } from "./automation-list/automation-list.component";
import { AutomationDetailsComponent } from "./automation/automation-details/automation-details.component";
import { AutomationTasksComponent } from "./automation/automation-tasks/automation-tasks.component";
import { AutomationSchedulesComponent } from "./automation/automation-schedules/automation-schedules.component";
import { TaskFormComponent } from "./task-form/task-form.component";
import { ScheduleFormComponent } from "./schedule-form/schedule-form.component";

// Routes
import { AutomationRouting } from "./automation.routing";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        MatMenuModule,
        NgbModule,
        FontAwesome,
        SharedModule,
        AutomationRouting
    ],
    declarations: [
        AutomationComponent,
        AutomationFormComponent,
        AutomationListComponent,
        AutomationDetailsComponent,
        AutomationTasksComponent,
        AutomationSchedulesComponent,
        TaskFormComponent,
        ScheduleFormComponent
    ]
})
export class AutomationModule {}
