import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, ReplaySubject, Subscriber } from "rxjs";
import { map, share } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../../constants/constants";
import { TaskSet, Task, Schedule } from "./automation";
import { TimezonePipe } from "src/app/pipes/timezone.pipe";
import _ from "lodash";
import moment from "moment-timezone";
import { AuthService } from "src/app/services/auth.service";
import { APIResponse } from "src/app/models/shared";

@Injectable({
    providedIn: "root"
})
export class AutomationService {
    taskSets: Observable<TaskSet[]>;
    private taskSetsRS$: ReplaySubject<TaskSet[]>;
    private dataStore: {
        task_sets: TaskSet[];
    };

    private lastTaskSetsRefresh: number;
    private lastTaskSetRefresh: number;

    constructor(
        private authService: AuthService,
        private http: HttpClient,
        private translate: TranslateService,
        private timeZonePipe: TimezonePipe
    ) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            task_sets: []
        };

        this.lastTaskSetsRefresh = null;
        this.lastTaskSetRefresh = null;

        this.taskSetsRS$ = new ReplaySubject(1) as ReplaySubject<TaskSet[]>;
        this.taskSets = this.taskSetsRS$.asObservable();
    }

    private updateTaskSetStore(newTaskSet: TaskSet, merge: boolean): void {
        this.prepTaskSet(newTaskSet);
        const currentIndex = this.dataStore.task_sets.findIndex(ts => ts.id === newTaskSet.id);
        if (currentIndex === -1) {
            this.dataStore.task_sets.push(newTaskSet);
            return;
        } else if (merge) {
            const currentTaskSet = this.dataStore.task_sets[currentIndex];

            Object.assign(currentTaskSet, newTaskSet);
        } else {
            this.dataStore.task_sets[currentIndex] = newTaskSet;
        }
    }

    prepTaskSet(taskSet: TaskSet) {
        taskSet._frontData = {
            next_execution_time: null,
            next_execution_timezone: ""
        };

        if (!taskSet.schedules) taskSet.schedules = [];
        if (!taskSet.tasks) taskSet.tasks = [];

        // Prepare schedules presentation
        if (taskSet && taskSet.schedules && taskSet.schedules.length) {
            let next_execution_time: moment.Moment | null = null;
            for (let i = 0; i < taskSet.schedules.length; i++) {
                taskSet.schedules[i] = this.prepSchedule(taskSet.schedules[i]);
                if (!taskSet.schedules[i].next_execution_time) continue;

                if (
                    !next_execution_time ||
                    moment.utc(taskSet.schedules[i].next_execution_time) < next_execution_time
                ) {
                    next_execution_time = moment.utc(taskSet.schedules[i].next_execution_time);
                    taskSet._frontData.next_execution_time = taskSet.schedules[i]._frontData.tz_next_execution_time;
                    taskSet._frontData.next_execution_timezone = taskSet.schedules[i].timezone;
                }
            }
        }

        if (taskSet.resourceTags)
            taskSet.resourceTags.sort((a, b) =>
                a.name === b.name ? (a.id < b.id ? -1 : 1) : a.name < b.name ? -1 : 1
            );
    }

    refreshTaskSets(force?: boolean): Observable<TaskSet[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastTaskSetsRefresh <= 60000) return this.taskSets;
        this.lastTaskSetsRefresh = _.now();

        const taskSets$ = this.http
            .get<APIResponse<TaskSet[]>>(Constants.apiUrl + Constants.apiUrls.task_sets)
            .pipe(share());

        taskSets$.subscribe(
            data => {
                const taskSets: TaskSet[] = data.result;

                this.dataStore.task_sets.forEach((existingTaskSet, existingIndex) => {
                    const newIndex = taskSets.findIndex(ts => ts.id === existingTaskSet.id);
                    if (newIndex === -1) this.dataStore.task_sets.splice(existingIndex, 1);
                });

                taskSets.forEach(refreshedTaskSet => this.updateTaskSetStore(refreshedTaskSet, true));

                this.taskSetsRS$.next(Object.assign({}, this.dataStore).task_sets);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_TASK_SETS"), error)
        );
        return taskSets$.pipe(map(r => r.result));
    }

    refreshTaskSet(val: string | number, force?: boolean): Observable<TaskSet> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastTaskSetRefresh <= 60000) {
            return new Observable((observe: Subscriber<TaskSet>) => {
                if (typeof val === "number") observe.next(this.dataStore.task_sets.find(ts => ts.id === val));
                else observe.next(this.dataStore.task_sets.find(ts => ts.name === val));
                observe.complete();
            });
        }
        this.lastTaskSetRefresh = _.now();

        const id: number = typeof val === "number" ? val : this.dataStore.task_sets.find(ts => ts.name === val).id;

        const taskSet$ = this.http
            .get<APIResponse<TaskSet>>(Constants.apiUrl + Constants.apiUrls.task_sets + "/" + id)
            .pipe(share());

        taskSet$.subscribe(
            data => {
                const taskSet: TaskSet = data.result;
                taskSet.hasFullDetails = true;
                this.updateTaskSetStore(taskSet, false);

                this.taskSetsRS$.next(Object.assign({}, this.dataStore).task_sets);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_TASK_SET"), error)
        );
        return taskSet$.pipe(map(r => r.result));
    }

    getCachedTaskSet(name?: string, id?: number) {
        if (this.dataStore.task_sets && name) return this.dataStore.task_sets.find(ts => ts.name === name);
        if (this.dataStore.task_sets && id) return this.dataStore.task_sets.find(ts => ts.id === id);
        return undefined;
    }

    async addTaskSet(model: Record<string, unknown>) {
        try {
            const result = await this.http
                .post<APIResponse<TaskSet>>(Constants.apiUrl + Constants.apiUrls.task_sets, model)
                .toPromise();
            const taskSet: TaskSet = result.result;

            this.updateTaskSetStore(taskSet, true);

            this.taskSetsRS$.next(Object.assign({}, this.dataStore).task_sets);
            return taskSet;
        } catch (error) {
            return false;
        }
    }

    async updateTaskSet(taskSet: TaskSet, model: Record<string, unknown>) {
        try {
            const result = await this.http
                .put<APIResponse<TaskSet>>(
                    Constants.apiUrl + Constants.apiUrls.task_sets + "/" + `${taskSet.id}`,
                    model
                )
                .toPromise();
            const updatedTaskSet: TaskSet = result.result;

            this.updateTaskSetStore(updatedTaskSet, true);

            this.taskSetsRS$.next(Object.assign({}, this.dataStore).task_sets);
            return updatedTaskSet;
        } catch (error) {
            return false;
        }
    }

    async deleteTaskSet(taskSet: TaskSet) {
        try {
            const result = await this.http
                .delete<APIResponse<number>>(Constants.apiUrl + Constants.apiUrls.task_sets + "/" + `${taskSet.id}`)
                .toPromise();

            const deletedId: number = result.result;
            const tsIndex = this.dataStore.task_sets.findIndex(ts => ts.id === deletedId);
            if (tsIndex !== -1) this.dataStore.task_sets.splice(tsIndex, 1);

            this.taskSetsRS$.next(Object.assign({}, this.dataStore).task_sets);
            return true;
        } catch (error) {
            return false;
        }
    }

    prepTask(task: Task) {
        return task;
    }

    async addTask(taskSet: TaskSet, model: Record<string, unknown>) {
        try {
            const result = await this.http
                .post<APIResponse<Task>>(
                    Constants.apiUrl + Constants.apiUrls.task_sets + "/" + `${taskSet.id}` + "/tasks",
                    model
                )
                .toPromise();

            const task: Task = result.result;
            this.prepTask(task);

            return task;
        } catch (error) {
            return false;
        }
    }

    async updateTask(task: Task, model: Record<string, unknown>) {
        try {
            const result = await this.http
                .put<APIResponse<Task>>(
                    Constants.apiUrl + Constants.apiUrls.task_sets + "/" + task.task_set_id + "/tasks/" + task.id,
                    model
                )
                .toPromise();

            const updatedTask: Task = result.result;
            this.prepTask(updatedTask);

            return updatedTask;
        } catch (error) {
            return false;
        }
    }

    async deleteTask(task: Task) {
        try {
            await this.http
                .delete<APIResponse<number>>(
                    Constants.apiUrl + Constants.apiUrls.task_sets + "/" + task.task_set_id + "/tasks/" + task.id
                )
                .toPromise();

            return true;
        } catch (error) {
            return false;
        }
    }

    prepSchedule(schedule: Schedule) {
        const frontData = {
            tz_start_time: moment.utc(schedule.start_time).tz(schedule.timezone).format("MMM D, YYYY, h:mm:ss A"),
            tz_end_time: schedule.end_time
                ? moment.utc(schedule.end_time).tz(schedule.timezone).format("MMM D, YYYY, h:mm:ss A")
                : null,
            tz_next_execution_time: schedule.next_execution_time
                ? moment.utc(schedule.next_execution_time).tz(schedule.timezone).format("MMM D, YYYY, h:mm:ss A")
                : null
        };
        schedule._frontData = frontData;
        return schedule;
    }

    async addSchedule(taskSet: TaskSet, model: Record<string, unknown>) {
        try {
            const result = await this.http
                .post<APIResponse<Schedule>>(
                    Constants.apiUrl + Constants.apiUrls.task_sets + "/" + `${taskSet.id}` + "/schedules",
                    model
                )
                .toPromise();

            const schedule: Schedule = result.result;
            this.prepSchedule(schedule);

            return schedule;
        } catch (error) {
            return false;
        }
    }

    async updateSchedule(schedule: Schedule, model: Record<string, unknown>) {
        try {
            const result = await this.http
                .put<APIResponse<Schedule>>(
                    Constants.apiUrl +
                        Constants.apiUrls.task_sets +
                        "/" +
                        schedule.task_set_id +
                        "/schedules/" +
                        schedule.id,
                    model
                )
                .toPromise();

            const updatedSchedule: Schedule = result.result;
            this.prepSchedule(updatedSchedule);

            return updatedSchedule;
        } catch (error) {
            return false;
        }
    }

    async deleteSchedule(schedule: Schedule) {
        try {
            await this.http
                .delete<APIResponse<number>>(
                    Constants.apiUrl +
                        Constants.apiUrls.task_sets +
                        "/" +
                        schedule.task_set_id +
                        "/schedules/" +
                        schedule.id
                )
                .toPromise();

            return true;
        } catch (error) {
            return false;
        }
    }

    async getTask(task: Task) {
        try {
            const data = await this.http
                .get<APIResponse<Task>>(
                    Constants.apiUrl + Constants.apiUrls.task_sets + "/" + task.task_set_id + "/tasks/" + task.id
                )
                .toPromise();

            const t: Task = data.result;
            this.prepTask(t);

            return t;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_TASK"));
            return false;
        }
    }
}
