import { Component, OnInit, OnDestroy } from "@angular/core";
import { Location, TitleCasePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";
import { TaskSet } from "../automation";
import { AutomationService } from "../automation.service";
import { SharedService } from "src/app/services/shared.service";
import { TitleService } from "../../../services/title.service";
import { TranslateService } from "@ngx-translate/core";
import { UntypedFormControl, Validators } from "@angular/forms";
@Component({
    selector: "app-automation-form",
    templateUrl: "./automation-form.component.html",
    providers: [TitleCasePipe]
})
export class AutomationFormComponent implements OnInit, OnDestroy {
    taskSet: TaskSet;
    taskSetName: string;
    taskSetNames: string[];

    action: string;

    loading = true;
    saving = false;

    submitted = false;
    minLength = 2;
    isEdit = false;
    isClone = false;
    constants = Constants;

    private taskSetsSubscription: Subscription;

    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.name)
    ]);

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private as: AutomationService,
        private sharedService: SharedService,
        private translate: TranslateService,
        private titleService: TitleService,
        private titlecasePipe: TitleCasePipe
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.taskSetName = params.get("name");
            this.action = params.get("action");
            if (this.taskSetName) {
                this.taskSet = Object.assign({}, this.as.getCachedTaskSet(this.taskSetName));
                this.setTitle();
                // Check if found in cache, if not get
                if (this.sharedService.isEmptyObject(this.taskSet)) {
                    this.as
                        .refreshTaskSets(true)
                        .pipe(take(1))
                        .subscribe(async () => {
                            this.taskSet = Object.assign({}, this.as.getCachedTaskSet(this.taskSetName));

                            this.taskSet = await this.as.refreshTaskSet(this.taskSet.id, true).toPromise();
                            this.setTitle();
                            if (this.action === "clone") {
                                this.taskSet.name = "";
                            }
                            this.loading = false;
                        });
                } else {
                    this.loading = false;
                }
            } else {
                this.loading = false;
            }
        });
    }

    setTitle() {
        // Set Title
        this.titleService.setTitle(
            this.translate.instant("TASK_SET") +
                " - " +
                (this.action ? this.titlecasePipe.transform(this.action) : "New") +
                " " +
                (this.taskSet && this.taskSet.name ? this.taskSet.name : "")
        );
    }

    ngOnInit() {
        if (this.action) {
            if (this.action === "edit") {
                this.isEdit = true;
            } else if (this.action === "clone") {
                this.isClone = true;
                this.taskSet.name = "";
            }
        }

        if (!this.taskSet && !this.isClone && !this.isEdit) {
            this.taskSet = new TaskSet();
            this.tagsControl.setValue([]);
            this.nameControl.setValue(null);
        }

        this.setTitle();

        this.as.refreshTaskSets();

        this.taskSetsSubscription = this.as.taskSets.subscribe((taskSets: TaskSet[]) => {
            this.taskSetNames = _.map(taskSets, "name");
            this.tagsControl.setValue(this.taskSet.resourceTags);

            // If Edit remove the current name from names list
            if (this.isEdit) {
                this.nameControl.setValue(this.taskSet.name);
                this.taskSetNames = _.without(this.taskSetNames, this.taskSetName);
            }
        });
    }

    ngOnDestroy() {
        this.taskSetsSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;

        const model = {
            name: this.nameControl.value,
            resource_tag_ids: _.map(this.tagsControl.value, "id")
        };

        if (this.isEdit) {
            const result = await this.as.updateTaskSet(this.taskSet, model);
            if (result) {
                this.saving = false;

                this.router.navigate([Constants.urls.automation, this.taskSet.name]);
            } else this.saving = false;
        } else {
            const result = await this.as.addTaskSet(model);
            if (result) {
                this.saving = false;
                this.router.navigate([Constants.urls.automation, model.name]);
            } else this.saving = false;
        }
    }

    cancel() {
        if (this.isEdit || this.isClone) this.router.navigate([Constants.urls.automation, this.taskSetName]);
        else this.router.navigate([Constants.urls.automation]);
    }
}
