import { Component, OnInit, OnChanges, OnDestroy, Input, ViewChildren, QueryList, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Subscription } from "rxjs";

import { Constants } from "../../../../constants/constants";
import { ModalService } from "../../../../components/shared/modals/modal.service";

import { AutomationService } from "../../automation.service";
import { TaskSet, Task } from "../../automation";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { KeyMap } from "src/app/models/shared";
import { TranslateService } from "@ngx-translate/core";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ZxEditTableRowButtonsComponent } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.component";
import { assignEditTableRowInputsFactory } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.table-adapter";

@Component({
    selector: "app-automation-tasks",
    templateUrl: "./automation-tasks.component.html"
})
export class AutomationTasksComponent implements OnInit, OnChanges, OnDestroy {
    @Input() taskSet: TaskSet;
    @Input() canEdit: boolean;
    @Input() autoRows?: boolean = true;
    @Input() bordered?: boolean;

    tasks: Task[];
    private tasksBS$ = new BehaviorSubject<Task[]>([]);
    private taskSetsSubscription: Subscription;

    tableColumnsSchema: TableSchema<KeyMap<Task>>[] = [
        {
            header: this.translate.instant("RUN"),
            columnDef: "run",
            width: 240,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Task>>(
                row =>
                    row.start_offset_sec === 0
                        ? this.translate.instant("IMMEDIATELY")
                        : row.start_offset_sec === 1
                        ? this.translate.instant("AFTER") +
                          " " +
                          row.start_offset_sec +
                          " " +
                          this.translate.instant("SECOND").toLowerCase()
                        : 60 > row.start_offset_sec && row.start_offset_sec !== 1 && row.start_offset_sec !== 0
                        ? this.translate.instant("AFTER") +
                          " " +
                          row.start_offset_sec +
                          " " +
                          this.translate.instant("SECONDS").toLowerCase()
                        : row.start_offset_sec === 60
                        ? this.translate.instant("AFTER") +
                          " " +
                          row.start_offset_sec / 60 +
                          " " +
                          this.translate.instant("MINUTE").toLowerCase()
                        : row.start_offset_sec > 60
                        ? this.translate.instant("AFTER") +
                          " " +
                          row.start_offset_sec / 60 +
                          " " +
                          this.translate.instant("MINUTES").toLowerCase()
                        : "",
                row =>
                    row.start_offset_sec === 0
                        ? this.translate.instant("IMMEDIATELY")
                        : row.start_offset_sec === 1
                        ? this.translate.instant("AFTER") +
                          " " +
                          row.start_offset_sec +
                          " " +
                          this.translate.instant("SECOND").toLowerCase()
                        : 60 > row.start_offset_sec && row.start_offset_sec !== 1 && row.start_offset_sec !== 0
                        ? this.translate.instant("AFTER") +
                          " " +
                          row.start_offset_sec +
                          " " +
                          this.translate.instant("SECONDS").toLowerCase()
                        : row.start_offset_sec === 60
                        ? this.translate.instant("AFTER") +
                          " " +
                          row.start_offset_sec / 60 +
                          " " +
                          this.translate.instant("MINUTE").toLowerCase()
                        : row.start_offset_sec > 60
                        ? this.translate.instant("AFTER") +
                          " " +
                          row.start_offset_sec / 60 +
                          " " +
                          this.translate.instant("MINUTES").toLowerCase()
                        : "",
                () => true
            ),
            sortBy: row => row.start_offset_sec
        },
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 160,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Task>>(
                row => row.name,
                row => row.name,
                () => true
            ),
            sortBy: row => row.name
        },
        {
            header: this.translate.instant("TYPE"),
            columnDef: "type",
            width: 160,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Task>>(
                row =>
                    row.action === "set_cluster_size" ||
                    row.action === "assign_target_to_channel" ||
                    row.action === "remove_target_from_channel"
                        ? this.translate.instant(row.action)
                        : row.action === "set_mute" && row.parameter === 1
                        ? this.translate.instant("MUTE")
                        : row.action === "set_mute" && row.parameter === 0
                        ? this.translate.instant("UNMUTE")
                        : row.action === "set_enabled" && row.parameter === 1
                        ? this.translate.instant("ENABLE")
                        : row.action === "set_enabled" && row.parameter === 0
                        ? this.translate.instant("DISABLE")
                        : row.action === "set_cqa" && row.parameter === 1
                        ? this.translate.instant("ACTIVATE_CONTENT_ANALYSIS")
                        : row.action === "set_cqa" && row.parameter === 0
                        ? this.translate.instant("DEACTIVATE_CONTENT_ANALYSIS")
                        : "",
                row =>
                    row.action === "set_cluster_size" ||
                    row.action === "assign_target_to_channel" ||
                    row.action === "remove_target_from_channel"
                        ? this.translate.instant(row.action)
                        : row.action === "set_mute" && row.parameter === 1
                        ? this.translate.instant("MUTE")
                        : row.action === "set_mute" && row.parameter === 0
                        ? this.translate.instant("UNMUTE")
                        : row.action === "set_enabled" && row.parameter === 1
                        ? this.translate.instant("ENABLE")
                        : row.action === "set_enabled" && row.parameter === 0
                        ? this.translate.instant("DISABLE")
                        : row.action === "set_cqa" && row.parameter === 1
                        ? this.translate.instant("ACTIVATE_CONTENT_ANALYSIS")
                        : row.action === "set_cqa" && row.parameter === 0
                        ? this.translate.instant("DEACTIVATE_CONTENT_ANALYSIS")
                        : "",
                () => true
            ),
            sortBy: row =>
                row.action === "set_cluster_size" ||
                row.action === "assign_target_to_channel" ||
                row.action === "remove_target_from_channel"
                    ? this.translate.instant(row.action)
                    : row.action === "set_mute" && row.parameter === 1
                    ? this.translate.instant("MUTE")
                    : row.action === "set_mute" && row.parameter === 0
                    ? this.translate.instant("UNMUTE")
                    : row.action === "set_enabled" && row.parameter === 1
                    ? this.translate.instant("ENABLE")
                    : row.action === "set_enabled" && row.parameter === 0
                    ? this.translate.instant("DISABLE")
                    : row.action === "set_cqa" && row.parameter === 1
                    ? this.translate.instant("ACTIVATE_CONTENT_ANALYSIS")
                    : row.action === "set_cqa" && row.parameter === 0
                    ? this.translate.instant("DEACTIVATE_CONTENT_ANALYSIS")
                    : ""
        },
        {
            header: this.translate.instant("ACTIONS"),
            columnDef: "actions",
            width: 105,
            align: "right",
            visible: true,
            stickyToLast: true,
            component: ZxEditTableRowButtonsComponent,
            assignComponentsInputs: assignEditTableRowInputsFactory<Task, Promise<void>>({
                canEditCallBack: row => this.canEdit,
                canCloneCallBack: row => this.canEdit,
                canDeleteCallBack: row => this.canEdit,
                editRef: row => this.editTask(row),
                cloneRef: row => this.cloneTask(row),
                deleteRef: row => this.deleteTask(row)
            })
        }
    ];

    constructor(
        private as: AutomationService,
        private modalService: ModalService,
        private router: Router,
        private mixpanelService: MixpanelService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.getTableData();
        this.prepTableData();

        this.taskSetsSubscription = this.as.taskSets.subscribe(taskSets => {
            this.taskSet = taskSets.find(ts => ts.id === this.taskSet.id);
            this.tasks = this.taskSet.tasks;
            this.prepTableData();
        });
    }

    ngOnDestroy() {
        this.taskSetsSubscription.unsubscribe();
    }

    getTableData() {
        this.tasks = this.taskSet.tasks;
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.task_set) {
            // Make sure it's not initial load
            if (changes.task_set.previousValue) {
                this.getTableData();
                this.prepTableData();
            }
        }
    }

    get tasks$() {
        return this.tasksBS$.asObservable();
    }

    private prepTableData() {
        if (this.tasks) {
            this.tasksBS$.next(this.tasks);
        }
    }

    addTask() {
        this.router.navigate([Constants.urls.automation, this.taskSet.name, "tasks", "new"]);
    }

    editTask(task: Task) {
        this.router.navigate([Constants.urls.automation, this.taskSet.name, "tasks", task.id, "edit"]);
    }

    cloneTask(task: Task) {
        this.router.navigate([Constants.urls.automation, this.taskSet.name, "tasks", task.id, "clone"]);
    }

    async deleteTask(task: Task) {
        await this.modalService.confirm(
            "DELETE",
            "TASK",
            async () => {
                const result = await this.as.deleteTask(task);
                if (result) {
                    this.mixpanelService.sendEvent("delete task", {
                        id: task.id
                    });
                    this.as.refreshTaskSets(true);
                } else {
                    return false;
                }
            },
            task.name
        );
    }
}
