import { Component, Input } from "@angular/core";
import { TaskSet } from "../../automation";

@Component({
    selector: "app-automation-details",
    templateUrl: "./automation-details.component.html"
})
export class AutomationDetailsComponent {
    @Input() taskSet: TaskSet;
    @Input() canEdit: boolean;
}
