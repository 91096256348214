<app-table-list
    [tableName]="'automation-tasks'"
    [displayTableName]="'TASKS' | translate"
    [data]="tasks$ | async"
    [(tableSchema)]="tableColumnsSchema"
    [showSelectionCheckbox]="false"
    [showReport]="false"
    [showPagination]="true"
    [showFilter]="false"
    [showColumnsSelection]="false"
    [selectable]="false"
    [hoverable]="false"
    [autoRows]="autoRows"
>
    <div class="form-group mb-2" [ngClass]="{ 'ms-3 mt-2': !bordered }">
        <button type="button" class="btn btn-outline-primary" (click)="addTask()" [disabled]="!canEdit" title="{{ 'ADD_TASK' | translate }}">
            <fa-icon icon="plus" size="sm"></fa-icon>
            {{ "ADD_TASK" | translate }}
        </button>
    </div>
</app-table-list>
