<app-table-list
    [ngClass]="{ 'mt-2': !bordered }"
    [tableName]="'automation-schedules'"
    [displayTableName]="'SCHEDULES' | translate"
    [data]="schedules$ | async"
    [(tableSchema)]="tableColumnsSchema"
    [showSelectionCheckbox]="false"
    [showReport]="false"
    [showPagination]="true"
    [showFilter]="false"
    [selectable]="false"
    [hoverable]="false"
    [autoRows]="autoRows"
>
    <div class="form-group mb-0" [ngClass]="{ 'ms-3': !bordered }">
        <button type="button" class="btn btn-outline-primary" (click)="addSchedule()" [disabled]="!canEdit" title="{{ 'ADD_SCHEDULE' | translate }}">
            <fa-icon icon="plus" size="sm"></fa-icon>
            {{ "ADD_SCHEDULE" | translate }}
        </button>
    </div>
</app-table-list>
