<content-loading-animation *ngIf="loading"></content-loading-animation>

<form id="form" #form="ngForm" (ngSubmit)="form.valid && onSubmit(form)" *ngIf="!loading">
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1 *ngIf="!isClone && !isEdit">{{ "CREATE_NEW_TASK" | translate }}</h1>
                    <h1 *ngIf="isClone">{{ "CLONE_TASK" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_TASK" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <section id="info">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INFO' | translate }}">
                                {{ "INFO" | translate }}
                            </h3>
                        </div>
                        <!-- Name -->
                        <div class="form-group">
                            <label for="name" [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                                >{{ "NAME" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ></label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                placeholder="{{ 'NAME' | translate }}"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                                [(ngModel)]="task.name"
                                required
                                minlength="{{ minLength }}"
                                pattern="{{ constants.validators.name }}"
                                #name="ngModel"
                            />
                            <div *ngIf="name.invalid" class="invalid-feedback">
                                <div *ngIf="name.errors.required">{{ "NAME" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                <div *ngIf="name.errors.minlength">
                                    {{ "NAME" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }} {{ "CHARACTERS_LONG" | translate }}.
                                </div>
                                <div *ngIf="name.errors.pattern">
                                    {{ "NAME" | translate }} {{ "CAN_NOT_CONTAIN_ANY_OF_THE_FOLLOWING_CHARACTERS:" | translate }} ' " `
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="config">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'CONFIGURATION' | translate }}">
                                {{ "CONFIGURATION" | translate }}
                            </h3>
                        </div>
                        <!-- # Minutes -->
                        <div class="form-group">
                            <label for="minutes" [ngClass]="{ 'is-invalid': form.submitted && minutes.errors }"
                                >{{ "#_OF_MINUTES_AFTER_START_TIME_TO_INITIATE_TASK" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ></label>
                            <input
                                type="number"
                                id="minutes"
                                name="minutes"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': form.submitted && minutes.errors }"
                                [(ngModel)]="startOffsetMins"
                                required
                                min="0"
                                max="10"
                                pattern="^\d+$"
                                #minutes="ngModel"
                            />
                            <div *ngIf="minutes.invalid" class="invalid-feedback">
                                <div *ngIf="minutes.errors.required">
                                    {{ "#_OF_MINUTES_AFTER_START_TIME_TO_INITIATE_TASK" | translate }} {{ "IS_REQUIRED" | translate }}.
                                </div>
                                <div *ngIf="minutes.errors.pattern || minutes.errors.max">
                                    {{ "#_OF_MINUTES_AFTER_START_TIME_TO_INITIATE_TASK" | translate }}
                                    {{ "MUST_BE_BETWEEN_0_AND_10" | translate | lowercase }}.
                                </div>
                            </div>
                        </div>

                        <!-- Task Type -->
                        <div class="form-group">
                            <label for="taskType">{{ "TASK_TYPE" | translate }}</label>
                            <ng-select
                                class="form-control"
                                id="taskType"
                                name="taskType"
                                [items]="typeOptions"
                                [clearable]="false"
                                bindLabel="name"
                                bindValue="value"
                                [(ngModel)]="task.action"
                                required
                            >
                            </ng-select>
                        </div>
                        <!-- Options -->
                        <!-- Mute/Unmute -->
                        <fieldset class="form-group" *ngIf="task.action === 'set_mute'">
                            <legend class="sr-only" for="mute_unmute">{{ "MUTE/UNMUTE" | translate }}</legend>
                            <mat-button-toggle-group name="mute_unmute" aria-label="mute unmute" [(ngModel)]="task.parameter">
                                <mat-button-toggle [value]="1">{{ "MUTE" | translate }}</mat-button-toggle>
                                <mat-button-toggle [value]="0">{{ "UNMUTE" | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </fieldset>
                        <!-- Enable/Disable -->
                        <fieldset class="form-group" *ngIf="task.action === 'set_enabled'">
                            <legend class="sr-only" for="enable_disable">{{ "ENABLE/DISABLE" | translate }}</legend>
                            <mat-button-toggle-group name="enable_disable" aria-label="enable disable" [(ngModel)]="task.parameter">
                                <mat-button-toggle [value]="1">{{ "ENABLE" | translate }}</mat-button-toggle>
                                <mat-button-toggle [value]="0">{{ "DISABLE" | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </fieldset>
                        <!-- CQA -->
                        <fieldset class="form-group" *ngIf="task.action === 'set_cqa'">
                            <legend class="sr-only" for="activate_deactivate">{{ "ACTIVATE/DEACTIVATE" | translate }}</legend>
                            <mat-button-toggle-group name="activate_deactivate" aria-label="activate deactivate" [(ngModel)]="task.parameter">
                                <mat-button-toggle [value]="1">{{ "ACTIVATE" | translate }} {{ "CONTENT_ANALYSIS" | translate }}</mat-button-toggle>
                                <mat-button-toggle [value]="0">{{ "DEACTIVATE" | translate }} {{ "CONTENT_ANALYSIS" | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </fieldset>
                        <!-- # of Broadcasters -->
                        <div class="form-group" *ngIf="task.action === 'set_cluster_size'">
                            <label for="broadcasters" [ngClass]="{ 'is-invalid': form.submitted && broadcasters.errors }"
                                >{{ "#_OF_BROADCASTERS" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ></label>
                            <input
                                type="number"
                                id="broadcasters"
                                name="broadcasters"
                                placeholder="{{ '#_OF_BROADCASTERS' | translate }}"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': form.submitted && broadcasters.errors }"
                                [(ngModel)]="task.parameter"
                                pattern="^\d+$"
                                min="0"
                                required
                                #broadcasters="ngModel"
                            />
                            <div *ngIf="broadcasters.invalid" class="invalid-feedback">
                                <div *ngIf="broadcasters.errors.required">{{ "#_OF_BROADCASTERS" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                <div *ngIf="broadcasters.errors.pattern">
                                    {{ "#_OF_BROADCASTERS" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate | lowercase }}.
                                </div>
                            </div>
                        </div>
                        <!-- Select Cluster -->
                        <div class="form-group mb-0" *ngIf="task.action === 'set_cluster_size'">
                            <label for="cluster" [ngClass]="{ 'is-invalid': form.submitted && form.controls.cluster?.errors }"
                                >{{ "CLUSTER" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ></label>
                            <zx-cluster-select
                                name="cluster"
                                [(model)]="selectedSizeCluster"
                                [ngModel]="selectedSizeCluster"
                                required="true"
                                [ngClass]="{ 'is-invalid': form.submitted && form.controls.cluster?.errors }"
                            ></zx-cluster-select>
                            <div *ngIf="form.controls.cluster?.invalid" class="invalid-feedback">
                                <div *ngIf="form.controls.cluster?.errors.required">{{ "CLUSTER" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            </div>
                        </div>
                        <!-- Assign Target to Channel / Remove Target from Channel -->
                        <div *ngIf="task.action === 'assign_target_to_channel'">
                            <!-- Channel Type -->
                            <fieldset class="form-group">
                                <legend for="channel_type">{{ "CHANNEL_TYPE" | translate }}</legend>
                                <mat-button-toggle-group name="channel_type" aria-label="channel type" [(ngModel)]="channelType">
                                    <mat-button-toggle value="adaptive">{{ "ADAPTIVE_CHANNEL" | translate }}</mat-button-toggle>
                                    <mat-button-toggle value="delivery">{{ "PASS_THROUGH_CHANNEL" | translate }}</mat-button-toggle>
                                    <mat-button-toggle value="mediaconnect">{{ "MEDIACONNECT_FLOW" | translate }}</mat-button-toggle>
                                </mat-button-toggle-group>
                            </fieldset>
                            <!-- Adpative Channel -->
                            <div class="form-group" *ngIf="channelType === 'adaptive'">
                                <label for="adaptive_channel" [ngClass]="{ 'is-invalid': form.submitted && form.controls.adaptive_channel?.errors }"
                                    >{{ "ADAPTIVE_CHANNEL" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ></label>
                                <zx-adaptive-channel-select
                                    id="adaptive_channel"
                                    name="adaptive_channel"
                                    [(model)]="selectedAdaptiveChannel"
                                    required="channelType === 'adaptive'"
                                    [ngClass]="{ 'is-invalid': form.submitted && form.controls.adaptive_channel?.errors }"
                                ></zx-adaptive-channel-select>
                                <div *ngIf="form.controls.adaptive_channel?.invalid" class="invalid-feedback">
                                    <div *ngIf="form.controls.adaptive_channel?.errors.required">
                                        {{ "ADAPTIVE_CHANNEL" | translate }} {{ "IS_REQUIRED" | translate }}.
                                    </div>
                                </div>
                            </div>
                            <!-- Delivery Channel -->
                            <div class="form-group" *ngIf="channelType === 'delivery'">
                                <label for="delivery_channel" [ngClass]="{ 'is-invalid': form.submitted && form.controls.delivery_channel?.errors }"
                                    >{{ "PASS_THROUGH_CHANNEL" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ></label>
                                <zx-delivery-channel-select
                                    id="delivery_channel"
                                    name="delivery_channel"
                                    [(model)]="selectedDeliveryChannel"
                                    required="channelType === 'delivery'"
                                    [ngClass]="{ 'is-invalid': form.submitted && form.controls.delivery_channel?.errors }"
                                ></zx-delivery-channel-select>
                                <div *ngIf="form.controls.delivery_channel?.invalid" class="invalid-feedback">
                                    <div *ngIf="form.controls.delivery_channel?.errors.required">
                                        {{ "PASS_THROUGH_CHANNEL" | translate }} {{ "IS_REQUIRED" | translate }}.
                                    </div>
                                </div>
                            </div>
                            <!-- MediaConnect Flow -->
                            <div class="form-group" *ngIf="channelType === 'mediaconnect'">
                                <label for="mediaconnect_flow" [ngClass]="{ 'is-invalid': form.submitted && form.controls.mediaconnect_flow?.errors }"
                                    >{{ "MEDIACONNECT_FLOW" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ></label>
                                <zx-mediaconnect-flow-select
                                    id="mediaconnect_flow"
                                    name="mediaconnect_flow"
                                    [(model)]="selectedMediaConnectFlow"
                                    required="channelType === 'mediaconnect'"
                                    [ngClass]="{ 'is-invalid': form.submitted && form.controls.mediaconnect_flow?.errors }"
                                ></zx-mediaconnect-flow-select>
                                <div *ngIf="form.controls.mediaconnect_flow?.invalid" class="invalid-feedback">
                                    <div *ngIf="form.controls.mediaconnect_flow?.errors.required">
                                        {{ "MEDIACONNECT_FLOW" | translate }} {{ "IS_REQUIRED" | translate }}.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- By -->
                        <fieldset
                            class="form-group"
                            *ngIf="
                                task.action === 'set_mute' ||
                                task.action === 'set_enabled' ||
                                task.action === 'set_cqa' ||
                                task.action === 'assign_target_to_channel' ||
                                task.action === 'remove_target_from_channel'
                            "
                        >
                            <legend for="by" *ngIf="task.action === 'set_mute' && task.parameter === 1">{{ "MUTE_BY" | translate }}</legend>
                            <legend for="by" *ngIf="task.action === 'set_mute' && task.parameter === 0">{{ "UNMUTE_BY" | translate }}</legend>
                            <legend for="by" *ngIf="task.action === 'set_enabled' && task.parameter === 1">{{ "ENABLE_BY" | translate }}</legend>
                            <legend for="by" *ngIf="task.action === 'set_enabled' && task.parameter === 0">{{ "DISABLE_BY" | translate }}</legend>
                            <legend for="by" *ngIf="task.action === 'assign_target_to_channel'">{{ "ASSIGN_TARGETS_TO_CHANNEL_BY" | translate }}</legend>
                            <legend for="by" *ngIf="task.action === 'remove_target_from_channel'">{{ "REMOVE_TARGETS_FROM_CHANNEL_BY" | translate }}</legend>
                            <legend for="by" *ngIf="task.action === 'set_cqa' && task.parameter === 1">
                                {{ "ACTIVATE" | translate }} {{ "CONTENT_ANALYSIS" | translate }} {{ "BY" | translate }}
                            </legend>
                            <legend for="by" *ngIf="task.action === 'set_cqa' && task.parameter === 0">
                                {{ "DEACTIVATE" | translate }} {{ "CONTENT_ANALYSIS" | translate }} {{ "BY" | translate }}
                            </legend>
                            <mat-button-toggle-group name="by" aria-label="by" [(ngModel)]="by">
                                <mat-button-toggle value="objects">{{ "ONE_OR_MORE_OBJECTS" | translate }}</mat-button-toggle>
                                <mat-button-toggle value="access_tags">
                                    <span *ngIf="task.action !== 'set_cqa'">{{ "OBJECTS_BASED_ON_TYPE_AND_TAGS" | translate }}</span>
                                    <span *ngIf="task.action === 'set_cqa'">{{ "OBJECTS_BASED_ON_TAGS" | translate }}</span>
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </fieldset>
                        <!-- Object Selectors -->
                        <fieldset
                            class="form-group mb-0"
                            [ngClass]="{ 'is-invalid': form.submitted && objectErrorMessage }"
                            *ngIf="
                                (task.action === 'set_mute' ||
                                    task.action === 'set_enabled' ||
                                    task.action === 'set_cqa' ||
                                    task.action === 'assign_target_to_channel' ||
                                    task.action === 'remove_target_from_channel') &&
                                by === 'objects'
                            "
                        >
                            <legend for="objects" class="sr-only">{{ "OBJECTS" | translate }}</legend>
                            <!-- Feeders -->
                            <div
                                class="form-group"
                                *ngIf="
                                    task.action !== 'set_enabled' &&
                                    task.action !== 'set_cqa' &&
                                    task.action !== 'assign_target_to_channel' &&
                                    task.action !== 'remove_target_from_channel'
                                "
                            >
                                <label for="feeders">{{ "FEEDERS" | translate }}</label>
                                <zx-zecs-select name="feeders" [(model)]="selectedFeeders" [zecType]="'FEEDERS'"></zx-zecs-select>
                            </div>
                            <!-- Clusters -->
                            <div
                                class="form-group"
                                *ngIf="
                                    task.action !== 'set_enabled' &&
                                    task.action !== 'set_cqa' &&
                                    task.action !== 'assign_target_to_channel' &&
                                    task.action !== 'remove_target_from_channel'
                                "
                            >
                                <label for="clusters">{{ "CLUSTERS" | translate }}</label>
                                <zx-clusters-select name="clusters" [(model)]="selectedClusters"></zx-clusters-select>
                            </div>
                            <!-- Receivers -->
                            <div
                                class="form-group"
                                *ngIf="
                                    task.action !== 'set_enabled' &&
                                    task.action !== 'set_cqa' &&
                                    task.action !== 'assign_target_to_channel' &&
                                    task.action !== 'remove_target_from_channel'
                                "
                            >
                                <label for="receivers">{{ "RECEIVERS" | translate }}</label>
                                <zx-zecs-select name="receivers" [(model)]="selectedReceivers" [zecType]="'RECEIVERS'"></zx-zecs-select>
                            </div>
                            <!-- Sources -->
                            <div class="form-group" *ngIf="task.action !== 'assign_target_to_channel' && task.action !== 'remove_target_from_channel'">
                                <label for="sources" [ngClass]="{ 'is-invalid': form.submitted && form.controls.sources?.errors }"
                                    >{{ "SOURCES" | translate }}<fa-icon icon="asterisk" size="xs" *ngIf="task.action === 'set_cqa'"></fa-icon
                                ></label>
                                <zx-sources-select
                                    name="sources"
                                    [(model)]="selectedSources"
                                    required="{{ task.action === 'set_cqa' }}"
                                    [ngClass]="{ 'is-invalid': form.submitted && form.controls.sources?.errors }"
                                ></zx-sources-select>
                                <div *ngIf="task.action === 'set_cqa' && form.controls.sources?.invalid" class="invalid-feedback">
                                    <div *ngIf="form.controls.sources?.errors.required">{{ "MUST_SELECT_AT_LEAST_ONE_SOURCE" | translate }}.</div>
                                </div>
                            </div>
                            <!-- Mediaconnect Sources -->
                            <div
                                class="form-group"
                                *ngIf="task.action !== 'assign_target_to_channel' && task.action !== 'remove_target_from_channel' && task.action !== 'set_cqa'"
                            >
                                <label for="mediaconnectSsources" [ngClass]="{ 'is-invalid': form.submitted && form.controls.mediaconnectSources?.errors }"
                                    >{{ "MEDIACONNECT_SOURCES" | translate }}<fa-icon icon="asterisk" size="xs" *ngIf="task.action === 'set_cqa'"></fa-icon
                                ></label>
                                <zx-mediaconnect-sources-select
                                    name="mediaconnectSources"
                                    [(model)]="selectedMediaConnectSources"
                                    [ngClass]="{ 'is-invalid': form.submitted && form.controls.mediaconnectSources?.errors }"
                                ></zx-mediaconnect-sources-select>
                            </div>
                            <!-- Mediaconnect Flows -->
                            <div
                                class="form-group"
                                *ngIf="task.action !== 'set_cqa' && task.action !== 'assign_target_to_channel' && task.action !== 'remove_target_from_channel'"
                            >
                                <label for="mediaconnect_flows">{{ "MEDIACONNECT_FLOWS" | translate }}</label>
                                <zx-mediaconnect-flows-select
                                    name="mediaconnect_flows"
                                    [(model)]="selectedMediaConnectFlows"
                                    clearable="true"
                                ></zx-mediaconnect-flows-select>
                            </div>
                            <!-- Adaptive Channels -->
                            <div
                                class="form-group"
                                *ngIf="task.action !== 'set_cqa' && task.action !== 'assign_target_to_channel' && task.action !== 'remove_target_from_channel'"
                            >
                                <label for="adaptive_channels">{{ "ADAPTIVE_CHANNELS" | translate }}</label>
                                <zx-adaptive-channels-select
                                    name="adaptive_channels"
                                    [(model)]="selectedAdaptiveChannels"
                                    clearable="true"
                                ></zx-adaptive-channels-select>
                            </div>
                            <!-- Delivery Channels -->
                            <div
                                class="form-group"
                                *ngIf="task.action !== 'set_cqa' && task.action !== 'assign_target_to_channel' && task.action !== 'remove_target_from_channel'"
                            >
                                <label for="delivery_channels">{{ "PASS_THROUGH_CHANNELS" | translate }}</label>
                                <zx-delivery-channels-select
                                    name="delivery_channels"
                                    [(model)]="selectedDeliveryChannels"
                                    clearable="true"
                                ></zx-delivery-channels-select>
                            </div>
                            <!-- Targets -->
                            <div class="form-group mb-0" *ngIf="task.action !== 'set_cqa' && task.action !== 'assign_target_to_channel'">
                                <label for="targets" [ngClass]="{ 'is-invalid': form.submitted && form.controls.targets?.errors }"
                                    >{{ "TARGETS" | translate
                                    }}<fa-icon icon="asterisk" size="xs" *ngIf="task.action === 'remove_target_from_channel'"></fa-icon
                                ></label>
                                <zx-targets-select
                                    name="targets"
                                    [(model)]="selectedTargets"
                                    required="{{ task.action === 'remove_target_from_channel' }}"
                                    [ngClass]="{ 'is-invalid': form.submitted && form.controls.targets?.errors }"
                                ></zx-targets-select>
                                <div *ngIf="task.action === 'remove_target_from_channel' && form.controls.targets?.invalid" class="invalid-feedback">
                                    <div *ngIf="form.controls.targets?.errors.required">{{ "MUST_SELECT_AT_LEAST_ONE_TARGET" | translate }}.</div>
                                </div>
                            </div>
                            <!-- HTTP Targets for Adaptive -->
                            <div class="form-group mb-0" *ngIf="task.action === 'assign_target_to_channel' && channelType === 'adaptive'">
                                <label for="adaptiveTargets" [ngClass]="{ 'is-invalid': form.submitted && form.controls.adaptiveTargets?.errors }"
                                    >{{ "TARGETS" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ></label>
                                <zx-targets-select
                                    name="adaptiveTargets"
                                    [adaptive]="true"
                                    [(model)]="selectedAdaptiveTargets"
                                    required="true"
                                    [ngClass]="{ 'is-invalid': form.submitted && form.controls.adaptiveTargets?.errors }"
                                ></zx-targets-select>
                                <div *ngIf="form.controls.adaptiveTargets?.invalid" class="invalid-feedback">
                                    <div *ngIf="form.controls.adaptiveTargets?.errors.required">{{ "MUST_SELECT_AT_LEAST_ONE_TARGET" | translate }}.</div>
                                </div>
                            </div>
                            <!-- Targets for Delivery -->
                            <div class="form-group mb-0" *ngIf="task.action === 'assign_target_to_channel' && channelType === 'delivery'">
                                <label for="deliveryTargets" [ngClass]="{ 'is-invalid': form.submitted && form.controls.deliveryTargets?.errors }"
                                    >{{ "TARGETS" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ></label>
                                <zx-targets-select
                                    name="deliveryTargets"
                                    [delivery]="true"
                                    [(model)]="selectedDeliveryTargets"
                                    required="true"
                                    [ngClass]="{ 'is-invalid': form.submitted && form.controls.deliveryTargets?.errors }"
                                ></zx-targets-select>
                                <div *ngIf="form.controls.deliveryTargets?.invalid" class="invalid-feedback">
                                    <div *ngIf="form.controls.deliveryTargets?.errors.required">{{ "MUST_SELECT_AT_LEAST_ONE_TARGET" | translate }}.</div>
                                </div>
                            </div>
                            <!-- Targets for Mediaconenct -->
                            <div class="form-group mb-0" *ngIf="task.action === 'assign_target_to_channel' && channelType === 'mediaconnect'">
                                <label for="mediaConnectTargets" [ngClass]="{ 'is-invalid': form.submitted && form.controls.mediaConnectTargets?.errors }"
                                    >{{ "TARGETS" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ></label>
                                <zx-targets-select
                                    name="mediaConnectTargets"
                                    [mediaconnect]="true"
                                    [(model)]="selectedMediaConnectTargets"
                                    required="true"
                                    [ngClass]="{ 'is-invalid': form.submitted && form.controls.mediaConnectTargets?.errors }"
                                ></zx-targets-select>
                                <div *ngIf="form.controls.mediaConnectTargets?.invalid" class="invalid-feedback">
                                    <div *ngIf="form.controls.mediaConnectTargets?.errors.required">{{ "MUST_SELECT_AT_LEAST_ONE_TARGET" | translate }}.</div>
                                </div>
                            </div>
                            <!-- Object Error -->
                            <div *ngIf="form.submitted && objectErrorMessage" class="invalid-feedback mt-1">
                                <div>{{ "MUST_SELECT_AT_LEAST_ONE_OBJECT" | translate }}.</div>
                            </div>
                        </fieldset>
                        <!-- Types -->
                        <fieldset
                            class="form-group bordered"
                            [ngClass]="{ 'is-invalid': form.submitted && typeErrorMessage }"
                            *ngIf="
                                (task.action === 'set_mute' ||
                                    task.action === 'set_enabled' ||
                                    task.action === 'assign_target_to_channel' ||
                                    task.action === 'remove_target_from_channel') &&
                                by === 'access_tags'
                            "
                        >
                            <legend for="types">{{ "TYPES" | translate }}</legend>
                            <div class="row">
                                <div class="col-12 col-xs-6" *ngIf="task.action !== 'assign_target_to_channel' && task.action !== 'remove_target_from_channel'">
                                    <div class="form-check" *ngIf="task.action !== 'set_cqa' && task.action !== 'set_enabled'">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="feedersObject"
                                            name="feedersObject"
                                            [disabled]="task.action === 'set_enabled'"
                                            [(ngModel)]="task.objects_by_tags.feeders"
                                        />
                                        <label class="form-check-label" for="feedersObject">{{ "FEEDERS" | translate }}</label>
                                    </div>
                                    <div class="form-check" *ngIf="task.action !== 'set_cqa' && task.action !== 'set_enabled'">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="clustersObject"
                                            name="clustersObject"
                                            [disabled]="task.action === 'set_enabled'"
                                            [(ngModel)]="task.objects_by_tags.clusters"
                                        />
                                        <label class="form-check-label" for="clustersObject">{{ "CLUSTERS" | translate }}</label>
                                    </div>
                                    <div class="form-check" *ngIf="task.action !== 'set_cqa' && task.action !== 'set_enabled'">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="broadcastersObject"
                                            name="broadcastersObject"
                                            [disabled]="task.action === 'set_enabled'"
                                            [(ngModel)]="task.objects_by_tags.broadcasters"
                                        />
                                        <label class="form-check-label" for="broadcastersObject">{{ "BROADCASTERS" | translate }}</label>
                                    </div>
                                    <div class="form-check" *ngIf="task.action !== 'set_cqa' && task.action !== 'set_enabled'">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="receiversObject"
                                            name="receiversObject"
                                            [disabled]="task.action === 'set_enabled'"
                                            [(ngModel)]="task.objects_by_tags.receivers"
                                        />
                                        <label class="form-check-label" for="receiversObject">{{ "RECEIVERS" | translate }}</label>
                                    </div>
                                    <div class="form-check">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="sourcesObject"
                                            name="sourcesObject"
                                            [disabled]="task.action === 'set_cqa'"
                                            [(ngModel)]="task.objects_by_tags.sources"
                                        />
                                        <label class="form-check-label" for="sourcesObject">{{ "SOURCES" | translate }}</label>
                                    </div>
                                    <div class="form-check">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="mediaconnectSourcesObject"
                                            name="mediaconnectSourcesObject"
                                            [disabled]="task.action === 'set_cqa' || task.action === 'set_enabled'"
                                            [(ngModel)]="task.objects_by_tags.mediaconnect_sources"
                                        />
                                        <label class="form-check-label" for="mediaconnectSourcesObject">{{ "MEDIACONNECT_SOURCES" | translate }}</label>
                                    </div>
                                    <div class="form-check" *ngIf="task.action !== 'set_cqa'">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="mediaconnectFlowsObject"
                                            name="mediaconnectFlowsObject"
                                            [(ngModel)]="task.objects_by_tags.mediaconnect_flows"
                                        />
                                        <label class="form-check-label" for="mediaconnectFlowsObject">{{ "MEDIACONNECT_FLOWS" | translate }}</label>
                                    </div>
                                    <div class="form-check" *ngIf="task.action !== 'set_cqa'">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="adaptiveChannelsObject"
                                            name="adaptiveChannelsObject"
                                            [(ngModel)]="task.objects_by_tags.adaptive_channels"
                                        />
                                        <label class="form-check-label" for="adaptiveChannelsObject">{{ "ADAPTIVE_CHANNELS" | translate }}</label>
                                    </div>
                                    <div class="form-check" *ngIf="task.action !== 'set_cqa'">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="passThroughChannelsObject"
                                            name="passThroughChannelsObject"
                                            [(ngModel)]="task.objects_by_tags.delivery_channels"
                                        />
                                        <label class="form-check-label" for="passThroughChannelsObject">{{ "PASS_THROUGH_CHANNELS" | translate }}</label>
                                    </div>
                                </div>
                                <div class="col-12 col-xs-6">
                                    <div
                                        class="form-check"
                                        *ngIf="task.action !== 'set_cqa' && !(task.action === 'assign_target_to_channel' && channelType !== 'adaptive')"
                                    >
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="publishingTargetsObject"
                                            name="publishingTargetsObject"
                                            [disabled]="task.action === 'assign_target_to_channel' && channelType === 'delivery'"
                                            [(ngModel)]="task.objects_by_tags.publishing_targets"
                                        />
                                        <label class="form-check-label" for="publishingTargetsObject">{{ "HTTP_TARGETS" | translate }}</label>
                                    </div>
                                    <div
                                        class="form-check"
                                        *ngIf="task.action !== 'set_cqa' && !(task.action === 'assign_target_to_channel' && channelType !== 'delivery')"
                                    >
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="rtmpTargetsObject"
                                            name="rtmpTargetsObject"
                                            [disabled]="task.action === 'assign_target_to_channel' && channelType !== 'delivery'"
                                            [(ngModel)]="task.objects_by_tags.rtmp_targets"
                                        />
                                        <label class="form-check-label" for="rtmpTargetsObject">{{ "RTMP_TARGETS" | translate }}</label>
                                    </div>
                                    <div
                                        class="form-check"
                                        *ngIf="task.action !== 'set_cqa' && !(task.action === 'assign_target_to_channel' && channelType === 'adaptive')"
                                    >
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="udprtpTargetsObject"
                                            name="udprtpTargetsObject"
                                            [disabled]="task.action === 'assign_target_to_channel' && channelType === 'adaptive'"
                                            [(ngModel)]="task.objects_by_tags.udp_rtp_targets"
                                        />
                                        <label class="form-check-label" for="udprtpTargetsObject">{{ "UDP/RTP_TARGETS" | translate }}</label>
                                    </div>
                                    <div
                                        class="form-check"
                                        *ngIf="task.action !== 'set_cqa' && !(task.action === 'assign_target_to_channel' && channelType === 'adaptive')"
                                    >
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="ristTargetsObject"
                                            name="ristTargetsObject"
                                            [disabled]="task.action === 'assign_target_to_channel' && channelType === 'adaptive'"
                                            [(ngModel)]="task.objects_by_tags.rist_targets"
                                        />
                                        <label class="form-check-label" for="ristTargetsObject">{{ "RIST_TARGETS" | translate }}</label>
                                    </div>
                                    <div
                                        class="form-check"
                                        *ngIf="task.action !== 'set_cqa' && !(task.action === 'assign_target_to_channel' && channelType !== 'delivery')"
                                    >
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="srtTargetsObject"
                                            name="srtTargetsObject"
                                            [disabled]="task.action === 'assign_target_to_channel' && channelType !== 'delivery'"
                                            [(ngModel)]="task.objects_by_tags.srt_targets"
                                        />
                                        <label class="form-check-label" for="srtTargetsObject">{{ "SRT_TARGETS" | translate }}</label>
                                    </div>
                                    <div
                                        class="form-check"
                                        *ngIf="task.action !== 'set_cqa' && !(task.action === 'assign_target_to_channel' && channelType !== 'delivery')"
                                    >
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="ndiTargetsObject"
                                            name="ndiTargetsObject"
                                            [disabled]="task.action === 'assign_target_to_channel' && channelType !== 'delivery'"
                                            [(ngModel)]="task.objects_by_tags.ndi_targets"
                                        />
                                        <label class="form-check-label" for="ndiTargetsObject">{{ "NDI_TARGETS" | translate }}</label>
                                    </div>
                                    <div
                                        class="form-check"
                                        *ngIf="task.action !== 'set_cqa' && !(task.action === 'assign_target_to_channel' && channelType === 'adaptive')"
                                    >
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="pullTargetsObject"
                                            name="pullTargetsObject"
                                            [disabled]="task.action === 'assign_target_to_channel' && channelType === 'adaptive'"
                                            [(ngModel)]="task.objects_by_tags.pull_targets"
                                        />
                                        <label class="form-check-label" for="pullTargetsObject">{{ "PULL_TARGETS" | translate }}</label>
                                    </div>
                                    <div
                                        class="form-check"
                                        *ngIf="task.action !== 'set_cqa' && !(task.action === 'assign_target_to_channel' && channelType === 'adaptive')"
                                    >
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="pushTargetsObject"
                                            name="pushTargetsObject"
                                            [disabled]="task.action === 'assign_target_to_channel' && channelType === 'adaptive'"
                                            [(ngModel)]="task.objects_by_tags.push_targets"
                                        />
                                        <label class="form-check-label" for="pushTargetsObject">{{ "PUSH_TARGETS" | translate }}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <!-- Type Error Message -->
                                    <div *ngIf="form.submitted && typeErrorMessage" class="invalid-feedback">
                                        <div>{{ "MUST_SELECT_AT_LEAST_ONE_TYPE" | translate }}.</div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <!-- Access Tags -->
                        <div
                            class="form-group mb-0"
                            *ngIf="
                                (task.action === 'set_mute' ||
                                    task.action === 'set_enabled' ||
                                    task.action === 'set_cqa' ||
                                    task.action === 'assign_target_to_channel' ||
                                    task.action === 'remove_target_from_channel') &&
                                by === 'access_tags'
                            "
                        >
                            <label for="resource_tag_ids" [ngClass]="{ 'is-invalid': form.submitted && tags.errors }"
                                >{{ "TAGS" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><fa-icon icon="info-circle" [ngbTooltip]="ATContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                                <ng-template #ATContent>{{ "TOOLTIP.TAGS" | translate }}</ng-template></label
                            >
                            <ng-select
                                name="resource_tag_ids"
                                [items]="resourceTags"
                                [loading]="loadingTags"
                                [multiple]="true"
                                [hideSelected]="true"
                                [closeOnSelect]="false"
                                [clearSearchOnAdd]="true"
                                bindLabel="name"
                                bindValue="id"
                                placeholder="{{ 'SELECT' | translate }} {{ 'TAGS' | translate }}"
                                [(ngModel)]="selectedResourceTags"
                                #tags="ngModel"
                                [ngClass]="{ 'form-control is-invalid': form.submitted && tags.errors }"
                                required
                            >
                            </ng-select>
                            <div *ngIf="tags.invalid" class="invalid-feedback">
                                <div *ngIf="tags.errors.required">{{ "AT_LEAST_1_TAG_IS_REQUIRED" | translate }}.</div>
                            </div>
                        </div>
                    </section>

                    <!-- Error -->
                    <app-error [marginBottom]="false" [marginTop]="true"></app-error>
                    <hr />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary me-2" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && form.invalid }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
