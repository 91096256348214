<div class="details-form">
    <div class="form-group row">
        <h5 class="col-12" translate>NAME</h5>
        <div class="col-12">
            <span>{{ taskSet.name }}</span>
        </div>
    </div>
    <div class="form-group row" *ngIf="taskSet.resourceTags && taskSet.resourceTags.length">
        <h5 class="col-12" translate>TAGS</h5>
        <div class="col-12">
            <span *ngFor="let tag of taskSet.resourceTags; index as i"><span *ngIf="i > 0">, </span>{{ tag.name }}</span>
        </div>
    </div>
    <div class="form-group row">
        <h5 class="col-12" translate>#_OF_TASKS</h5>
        <div class="col-12">
            <span>{{ taskSet.tasks.length }}</span>
        </div>
    </div>
    <div class="form-group row">
        <h5 class="col-12" translate>#_OF_SCHEDULES</h5>
        <div class="col-12">
            <span>{{ taskSet.schedules.length }}</span>
        </div>
    </div>
    <div class="form-group row">
        <h5 class="col-12" translate>NEXT_SCHEDULED_RUN</h5>
        <div class="col-12">
            <span *ngIf="taskSet._frontData.next_execution_time"
                >{{ taskSet._frontData.next_execution_time }} {{ (taskSet._frontData.next_execution_timezone | timezone : "utc").text }}</span
            >
            <span *ngIf="!taskSet._frontData.next_execution_time">-</span>
        </div>
    </div>
</div>
