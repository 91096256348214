import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { take } from "rxjs/operators";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";
import { Tag, Source, MediaConnectSource } from "../../../models/shared";
import { SharedService } from "../../../services/shared.service";
import { TaskSet, Task } from "../automation";
import { AutomationService } from "../automation.service";
import { TargetsService } from "../../targets/targets.service";
import { AnyTarget } from "../../channels/channel";
import { TitleCasePipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";

@Component({
    selector: "app-task-form",
    templateUrl: "./task-form.component.html",
    providers: [TitleCasePipe]
})
export class TaskFormComponent implements OnInit {
    taskSet: TaskSet;
    task: Task = null;
    taskSetName: string;
    taskID: string;
    resourceTags: Tag[];

    action: string;
    loading = true;
    loadingTags = true;

    saving = false;
    valid = false;
    objectErrorMessage = false;
    typeErrorMessage = false;

    submitted = false;
    minLength = 2;
    isEdit = false;
    isClone = false;
    constants = Constants;

    typeOptions = [
        { name: "Mute/Unmute", value: "set_mute" },
        { name: "Enable/Disable", value: "set_enabled" },
        { name: "Set Cluster Size", value: "set_cluster_size" },
        { name: "Activate/Deactivate Content Analysis", value: "set_cqa" },
        { name: "Assign Targets to Channel", value: "assign_target_to_channel" },
        { name: "Remove Targets from Channel", value: "remove_target_from_channel" }
    ];

    channelType: string;
    by: string;
    startOffsetMins: number;
    selectedResourceTags: number[];
    selectedSizeCluster: number;

    selectedFeeders: number[] = [];
    selectedClusters: number[] = [];
    selectedReceivers: number[] = [];
    selectedSources: Source[] = [];
    selectedMediaConnectSources: MediaConnectSource[] = [];
    selectedMediaConnectFlows: number[] = [];
    selectedAdaptiveChannels: number[] = [];
    selectedDeliveryChannels: number[] = [];
    selectedAdaptiveChannel: number = null;
    selectedDeliveryChannel: number = null;
    selectedMediaConnectFlow: number = null;

    publishingTargets: AnyTarget[] = [];
    rtmpTargets: AnyTarget[] = [];
    udprtpTargets: AnyTarget[] = [];
    ristTargets: AnyTarget[] = [];
    srtTargets: AnyTarget[] = [];
    ndiTargets: AnyTarget[] = [];
    pullTargets: AnyTarget[] = [];
    pushTargets: AnyTarget[] = [];

    selectedTargets: AnyTarget[] = [];
    selectedAdaptiveTargets: AnyTarget[] = [];
    selectedDeliveryTargets: AnyTarget[] = [];
    selectedMediaConnectTargets: AnyTarget[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private as: AutomationService,
        private sharedService: SharedService,
        private targetsService: TargetsService,
        private translate: TranslateService,
        private titleService: TitleService,
        private titlecasePipe: TitleCasePipe
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.taskSetName = params.get("name");
            this.taskID = params.get("id");
            this.action = params.get("action");

            if (this.taskSetName) {
                this.taskSet = Object.assign({}, this.as.getCachedTaskSet(this.taskSetName));
                // Check if found in cache, if not get
                if (this.sharedService.isEmptyObject(this.taskSet)) {
                    this.as
                        .refreshTaskSets(true)
                        .pipe(take(1))
                        .subscribe(() => {
                            this.taskSet = Object.assign({}, this.as.getCachedTaskSet(this.taskSetName));
                            if (this.taskID) this.getTask(this.taskSet, this.taskID);
                            else this.loading = false;
                        });
                } else {
                    if (this.taskID) this.getTask(this.taskSet, this.taskID);
                    else this.loading = false;
                }
            }
        });
    }

    async getTask(taskSet: TaskSet, id: string) {
        const task: Task = taskSet.tasks.find(t => t.id === parseInt(id, 10));
        const result = await this.as.getTask(task);
        if (result) {
            this.task = result;
            this.prepForm();
            this.loading = false;
        } else {
            this.loading = false;
        }
    }

    prepForm() {
        if (this.action && this.task) {
            if (this.action === "edit") {
                this.isEdit = true;
            } else if (this.action === "clone") {
                this.isClone = true;
                this.task.name = "";
            }

            if (this.task.start_offset_sec !== 0) this.startOffsetMins = this.task.start_offset_sec / 60;
            else this.startOffsetMins = 0;

            if (this.task.action === "set_cluster_size") {
                // Cluster Size
                // In case the cluster was deleted - selectedSizeCluster will remain blanc
                if (this.task.clusters?.length) this.selectedSizeCluster = this.task.clusters[0].id;
                this.channelType = "adaptive";
            } else if (this.task.action === "assign_target_to_channel") {
                // Assign Target to Channel
                if (this.task.adaptive_channels && this.task.adaptive_channels.length) {
                    this.channelType = "adaptive";
                    this.selectedAdaptiveChannel = this.task.adaptive_channels[0].id;
                } else if (this.task.delivery_channels && this.task.delivery_channels.length) {
                    this.channelType = "delivery";
                    this.selectedDeliveryChannel = this.task.delivery_channels[0].id;
                } else if (this.task.mediaconnect_flows && this.task.mediaconnect_flows.length) {
                    this.channelType = "mediaconnect";
                    this.selectedMediaConnectFlow = this.task.mediaconnect_flows[0].id;
                }
                if (this.task.objects_by_tags) {
                    this.by = "access_tags";
                } else {
                    this.by = "objects";
                    this.clearTags();
                }
            } else if (this.task.action === "remove_target_from_channel") {
                // Remove Target from Channel
                if (this.task.objects_by_tags) {
                    this.by = "access_tags";
                } else {
                    this.by = "objects";
                    this.clearTags();
                }
            } else if (
                this.task.action === "set_mute" ||
                this.task.action === "set_enabled" ||
                this.task.action === "set_cqa"
            ) {
                // Mute/Unmute && Enable/Disable && CQA
                this.channelType = "adaptive";
                if (this.task.objects_by_tags) {
                    this.by = "access_tags";
                } else {
                    this.by = "objects";
                    this.clearTags();
                }
            }

            this.by = this.by || "objects";

            // Access Tags
            if (this.task.objects_by_tags && this.task.objects_by_tags.tag_ids) {
                this.selectedResourceTags = this.task.objects_by_tags.tag_ids;
            }
            // Feeders
            if (this.task.feeders) {
                this.selectedFeeders = _.map(this.task.feeders, "id");
            }
            // Clusters
            if (this.task.clusters) {
                this.selectedClusters = _.map(this.task.clusters, "id");
            }
            // Receivers
            if (this.task.receivers) {
                this.selectedReceivers = _.map(this.task.receivers, "id");
            }
            // Sources
            if (this.task.sources) {
                this.selectedSources = this.task.sources;
            }
            // Media Connect Sources
            if (this.task.mediaconnect_sources) {
                this.selectedMediaConnectSources = this.task.mediaconnect_sources;
            }
            // Media Connect Flows
            if (this.task.mediaconnect_flows) {
                this.selectedMediaConnectFlows = _.map(this.task.mediaconnect_flows, "id");
            }
            // Adaptive Channels
            if (this.task.adaptive_channels) {
                this.selectedAdaptiveChannels = _.map(this.task.adaptive_channels, "id");
            }
            // Delivery Channels
            if (this.task.delivery_channels) {
                this.selectedDeliveryChannels = _.map(this.task.delivery_channels, "id");
            }

            // Targets
            this.publishingTargets = [];
            this.rtmpTargets = [];
            this.udprtpTargets = [];
            this.pullTargets = [];
            this.pushTargets = [];
            this.selectedTargets = [];
            this.selectedAdaptiveTargets = [];
            this.selectedDeliveryTargets = [];
            this.selectedMediaConnectTargets = [];

            // Publishing Targets
            if (this.task.publishing_targets) {
                this.publishingTargets = this.task.publishing_targets.map(t => this.targetsService.prepTarget(t));
            }

            // RTMP Targets
            if (this.task.rtmp_targets) {
                this.rtmpTargets = this.task.rtmp_targets.map(t => this.targetsService.prepTarget(t));
            }

            // UDP/RTP Targets
            if (this.task.udp_rtp_targets) {
                this.udprtpTargets = this.task.udp_rtp_targets.map(t => this.targetsService.prepTarget(t));
            }

            // RIST Targets
            if (this.task.rist_targets) {
                this.ristTargets = this.task.rist_targets.map(t => this.targetsService.prepTarget(t));
            }

            // SRT Targets
            if (this.task.srt_targets) {
                this.srtTargets = this.task.srt_targets.map(t => this.targetsService.prepTarget(t));
            }

            // NDI Targets
            if (this.task.ndi_targets) {
                this.ndiTargets = this.task.ndi_targets.map(t => this.targetsService.prepTarget(t));
            }

            // Pull Targets
            if (this.task.pull_targets) {
                this.pullTargets = this.task.pull_targets.map(t => this.targetsService.prepTarget(t));
            }

            // Push Targets
            if (this.task.push_targets) {
                this.pushTargets = this.task.push_targets.map(t => this.targetsService.prepTarget(t));
            }

            this.selectedTargets = [
                ...this.publishingTargets,
                ...this.rtmpTargets,
                ...this.udprtpTargets,
                ...this.ristTargets,
                ...this.srtTargets,
                ...this.ndiTargets,
                ...this.pullTargets,
                ...this.pushTargets
            ];
            this.selectedAdaptiveTargets = [...this.publishingTargets];
            this.selectedDeliveryTargets = [
                ...this.rtmpTargets,
                ...this.udprtpTargets,
                ...this.ristTargets,
                ...this.srtTargets,
                ...this.ndiTargets,
                ...this.pullTargets,
                ...this.pushTargets
            ];
            this.selectedMediaConnectTargets = [
                ...this.udprtpTargets,
                ...this.ristTargets,
                ...this.pullTargets,
                ...this.pushTargets
            ];
        }

        if (!this.task && !this.isClone && !this.isEdit) {
            this.task = new Task();
            this.task.name = "";
            this.startOffsetMins = 0;
            this.task.action = "set_mute";
            this.task.parameter = 1;
            this.channelType = "adaptive";
            this.by = "objects";
            this.clearTags();
        }

        // Set Title
        this.titleService.setTitle(
            this.translate.instant("TASK") +
                " - " +
                (this.action ? this.titlecasePipe.transform(this.action) : "New") +
                " " +
                (this.task && this.task.name ? this.task.name : "")
        );
    }

    ngOnInit() {
        this.loadingTags = true;
        this.sharedService
            .getResourceTagsByType("task_sets")
            .pipe(take(1))
            .subscribe((tags: Tag[]) => {
                this.loadingTags = false;
                this.resourceTags = tags;
            });

        this.prepForm();
    }

    checkObjects() {
        if (
            this.selectedFeeders.length === 0 &&
            this.selectedClusters.length === 0 &&
            this.selectedReceivers.length === 0 &&
            this.selectedSources.length === 0 &&
            this.selectedMediaConnectSources.length === 0 &&
            this.selectedAdaptiveChannels.length === 0 &&
            this.selectedMediaConnectFlows.length === 0 &&
            this.selectedDeliveryChannels.length === 0 &&
            this.selectedTargets.length === 0
        ) {
            return false;
        } else {
            return true;
        }
    }

    checkTags() {
        if (
            this.task.objects_by_tags &&
            this.task.objects_by_tags.feeders === 0 &&
            this.task.objects_by_tags.clusters === 0 &&
            this.task.objects_by_tags.broadcasters === 0 &&
            this.task.objects_by_tags.receivers === 0 &&
            this.task.objects_by_tags.sources === 0 &&
            this.task.objects_by_tags.mediaconnect_sources === 0 &&
            this.task.objects_by_tags.mediaconnect_flows === 0 &&
            this.task.objects_by_tags.adaptive_channels === 0 &&
            this.task.objects_by_tags.delivery_channels === 0 &&
            this.task.objects_by_tags.publishing_targets === 0 &&
            this.task.objects_by_tags.rtmp_targets === 0 &&
            this.task.objects_by_tags.udp_rtp_targets === 0 &&
            this.task.objects_by_tags.rist_targets === 0 &&
            this.task.objects_by_tags.srt_targets === 0 &&
            this.task.objects_by_tags.pull_targets === 0 &&
            this.task.objects_by_tags.push_targets === 0
        ) {
            return false;
        } else {
            return true;
        }
    }

    clearTags() {
        this.task.objects_by_tags = {
            clusters: 0,
            feeders: 0,
            broadcasters: 0,
            receivers: 0,
            sources: 0,
            mediaconnect_sources: 0,
            mediaconnect_flows: 0,
            adaptive_channels: 0,
            delivery_channels: 0,
            publishing_targets: 0,
            rtmp_targets: 0,
            udp_rtp_targets: 0,
            rist_targets: 0,
            srt_targets: 0,
            ndi_targets: 0,
            pull_targets: 0,
            push_targets: 0,
            tag_ids: []
        };
    }

    async onSubmit() {
        this.saving = true;
        let model = null;

        // Validation Checks
        if (this.task.action === "set_mute") {
            // Mute/Unmute
            if (this.by === "objects") {
                if (!this.checkObjects()) {
                    this.objectErrorMessage = true;
                    this.valid = false;
                } else {
                    this.objectErrorMessage = false;
                    this.valid = true;
                    //
                    model = {
                        name: this.task.name,
                        start_offset_sec: this.startOffsetMins * 60,
                        action: this.task.action,
                        parameter: this.task.parameter,
                        clusters: this.selectedClusters,
                        feeders: this.selectedFeeders,
                        broadcasters: [], // Remove?
                        receivers: this.selectedReceivers,
                        sources: _.map(this.selectedSources, "id"),
                        mediaconnect_sources: _.map(this.selectedMediaConnectSources, "id"),
                        mediaconnect_flows: this.selectedMediaConnectFlows,
                        adaptive_channels: this.selectedAdaptiveChannels,
                        delivery_channels: this.selectedDeliveryChannels,
                        publishing_targets: _.map(
                            this.selectedTargets.filter(t => t.adaptive === true),
                            "objId"
                        ),
                        rtmp_targets: _.map(
                            this.selectedTargets.filter(t => t.type === "rtmp"),
                            "objId"
                        ),
                        udp_rtp_targets: _.map(
                            this.selectedTargets.filter(t => t.type === "udp_rtp"),
                            "objId"
                        ),
                        rist_targets: _.map(
                            this.selectedTargets.filter(t => t.type === "rist"),
                            "objId"
                        ),
                        srt_targets: _.map(
                            this.selectedTargets.filter(t => t.type === "srt"),
                            "objId"
                        ),
                        ndi_targets: _.map(
                            this.selectedTargets.filter(t => t.type === "ndi"),
                            "objId"
                        ),
                        pull_targets: _.map(
                            this.selectedTargets.filter(t => t.type === "pull"),
                            "objId"
                        ),
                        push_targets: _.map(
                            this.selectedTargets.filter(t => t.type === "push" || t.type === "mediaconnect"),
                            "objId"
                        ),
                        objects_by_tags: {
                            clusters: 0,
                            feeders: 0,
                            broadcasters: 0,
                            receivers: 0,
                            sources: 0,
                            mediaconnect_sources: 0,
                            mediaconnect_flows: 0,
                            adaptive_channels: 0,
                            delivery_channels: 0,
                            publishing_targets: 0,
                            rtmp_targets: 0,
                            udp_rtp_targets: 0,
                            rist_targets: 0,
                            srt_targets: 0,
                            ndi_targets: 0,
                            pull_targets: 0,
                            push_targets: 0,
                            tag_ids: []
                        }
                    };
                }
            } else {
                if (!this.checkTags()) {
                    this.typeErrorMessage = true;
                    this.valid = false;
                } else {
                    this.typeErrorMessage = false;
                    this.valid = true;
                    //
                    model = {
                        name: this.task.name,
                        start_offset_sec: this.startOffsetMins * 60,
                        action: this.task.action,
                        parameter: this.task.parameter,
                        clusters: [],
                        feeders: [],
                        broadcasters: [], // Remove?
                        receivers: [],
                        sources: [],
                        adaptive_channels: [],
                        delivery_channels: [],
                        mediaconnect_sources: [],
                        mediaconnect_flows: [],
                        publishing_targets: [],
                        rtmp_targets: [],
                        udp_rtp_targets: [],
                        rist_targets: [],
                        srt_targets: [],
                        ndi_targets: [],
                        pull_targets: [],
                        push_targets: [],
                        objects_by_tags: {
                            clusters: this.task.objects_by_tags.clusters ? this.task.objects_by_tags.clusters : 0,
                            feeders: this.task.objects_by_tags.feeders ? this.task.objects_by_tags.feeders : 0,
                            broadcasters: this.task.objects_by_tags.broadcasters
                                ? this.task.objects_by_tags.broadcasters
                                : 0,
                            receivers: this.task.objects_by_tags.receivers ? this.task.objects_by_tags.receivers : 0,
                            sources: this.task.objects_by_tags.sources ? this.task.objects_by_tags.sources : 0,
                            mediaconnect_sources: this.task.objects_by_tags.mediaconnect_sources
                                ? this.task.objects_by_tags.mediaconnect_sources
                                : 0,
                            mediaconnect_flows: this.task.objects_by_tags.mediaconnect_flows
                                ? this.task.objects_by_tags.mediaconnect_flows
                                : 0,
                            adaptive_channels: this.task.objects_by_tags.adaptive_channels
                                ? this.task.objects_by_tags.adaptive_channels
                                : 0,
                            delivery_channels: this.task.objects_by_tags.delivery_channels
                                ? this.task.objects_by_tags.delivery_channels
                                : 0,
                            publishing_targets: this.task.objects_by_tags.publishing_targets
                                ? this.task.objects_by_tags.publishing_targets
                                : 0,
                            rtmp_targets: this.task.objects_by_tags.rtmp_targets
                                ? this.task.objects_by_tags.rtmp_targets
                                : 0,
                            udp_rtp_targets: this.task.objects_by_tags.udp_rtp_targets
                                ? this.task.objects_by_tags.udp_rtp_targets
                                : 0,
                            rist_targets: this.task.objects_by_tags.rist_targets
                                ? this.task.objects_by_tags.rist_targets
                                : 0,
                            srt_targets: this.task.objects_by_tags.srt_targets
                                ? this.task.objects_by_tags.srt_targets
                                : 0,
                            ndi_targets: this.task.objects_by_tags.ndi_targets
                                ? this.task.objects_by_tags.ndi_targets
                                : 0,
                            pull_targets: this.task.objects_by_tags.pull_targets
                                ? this.task.objects_by_tags.pull_targets
                                : 0,
                            push_targets: this.task.objects_by_tags.push_targets
                                ? this.task.objects_by_tags.push_targets
                                : 0,
                            tag_ids: this.selectedResourceTags
                        }
                    };
                }
            }
        } else if (this.task.action === "set_enabled") {
            // Enable/Disable
            if (this.by === "objects") {
                if (!this.checkObjects()) {
                    this.objectErrorMessage = true;
                    this.valid = false;
                } else {
                    this.objectErrorMessage = false;
                    this.valid = true;
                    //
                    model = {
                        name: this.task.name,
                        start_offset_sec: this.startOffsetMins * 60,
                        action: this.task.action,
                        parameter: this.task.parameter,
                        clusters: [],
                        feeders: [],
                        broadcasters: [], // Remove?
                        receivers: [],
                        sources: _.map(this.selectedSources, "id"),
                        mediaconnect_sources: _.map(this.selectedMediaConnectSources, "id"),
                        mediaconnect_flows: this.selectedMediaConnectFlows,
                        adaptive_channels: this.selectedAdaptiveChannels,
                        delivery_channels: this.selectedDeliveryChannels,
                        publishing_targets: _.map(
                            this.selectedTargets.filter(t => t.adaptive === true),
                            "objId"
                        ),
                        rtmp_targets: _.map(
                            this.selectedTargets.filter(t => t.type === "rtmp"),
                            "objId"
                        ),
                        udp_rtp_targets: _.map(
                            this.selectedTargets.filter(t => t.type === "udp_rtp"),
                            "objId"
                        ),
                        rist_targets: _.map(
                            this.selectedTargets.filter(t => t.type === "rist"),
                            "objId"
                        ),
                        srt_targets: _.map(
                            this.selectedTargets.filter(t => t.type === "srt"),
                            "objId"
                        ),
                        ndi_targets: _.map(
                            this.selectedTargets.filter(t => t.type === "ndi"),
                            "objId"
                        ),
                        pull_targets: _.map(
                            this.selectedTargets.filter(t => t.type === "pull"),
                            "objId"
                        ),
                        push_targets: _.map(
                            this.selectedTargets.filter(t => t.type === "push" || t.type === "mediaconnect"),
                            "objId"
                        ),
                        objects_by_tags: {
                            clusters: 0,
                            feeders: 0,
                            broadcasters: 0,
                            receivers: 0,
                            sources: 0,
                            mediaconnect_sources: 0,
                            mediaconnect_flows: 0,
                            adaptive_channels: 0,
                            delivery_channels: 0,
                            publishing_targets: 0,
                            rtmp_targets: 0,
                            udp_rtp_targets: 0,
                            rist_targets: 0,
                            srt_targets: 0,
                            ndi_targets: 0,
                            pull_targets: 0,
                            push_targets: 0,
                            tag_ids: []
                        }
                    };
                }
            } else {
                if (!this.checkTags()) {
                    this.typeErrorMessage = true;
                    this.valid = false;
                } else {
                    this.typeErrorMessage = false;
                    this.valid = true;
                    //
                    model = {
                        name: this.task.name,
                        start_offset_sec: this.startOffsetMins * 60,
                        action: this.task.action,
                        parameter: this.task.parameter,
                        clusters: [],
                        feeders: [],
                        broadcasters: [], // Remove?
                        receivers: [],
                        sources: [],
                        adaptive_channels: [],
                        delivery_channels: [],
                        mediaconnect_sources: [],
                        mediaconnect_flows: [],
                        publishing_targets: [],
                        rtmp_targets: [],
                        udp_rtp_targets: [],
                        rist_targets: [],
                        srt_targets: [],
                        ndi_targets: [],
                        pull_targets: [],
                        push_targets: [],
                        objects_by_tags: {
                            sources: this.task.objects_by_tags.sources ? this.task.objects_by_tags.sources : 0,
                            mediaconnect_sources: this.task.objects_by_tags.mediaconnect_sources
                                ? this.task.objects_by_tags.mediaconnect_sources
                                : 0,
                            mediaconnect_flows: this.task.objects_by_tags.mediaconnect_flows
                                ? this.task.objects_by_tags.mediaconnect_flows
                                : 0,
                            adaptive_channels: this.task.objects_by_tags.adaptive_channels
                                ? this.task.objects_by_tags.adaptive_channels
                                : 0,
                            delivery_channels: this.task.objects_by_tags.delivery_channels
                                ? this.task.objects_by_tags.delivery_channels
                                : 0,
                            publishing_targets: this.task.objects_by_tags.publishing_targets
                                ? this.task.objects_by_tags.publishing_targets
                                : 0,
                            rtmp_targets: this.task.objects_by_tags.rtmp_targets
                                ? this.task.objects_by_tags.rtmp_targets
                                : 0,
                            udp_rtp_targets: this.task.objects_by_tags.udp_rtp_targets
                                ? this.task.objects_by_tags.udp_rtp_targets
                                : 0,
                            rist_targets: this.task.objects_by_tags.rist_targets
                                ? this.task.objects_by_tags.rist_targets
                                : 0,
                            srt_targets: this.task.objects_by_tags.srt_targets
                                ? this.task.objects_by_tags.srt_targets
                                : 0,
                            ndi_targets: this.task.objects_by_tags.ndi_targets
                                ? this.task.objects_by_tags.ndi_targets
                                : 0,
                            pull_targets: this.task.objects_by_tags.pull_targets
                                ? this.task.objects_by_tags.pull_targets
                                : 0,
                            push_targets: this.task.objects_by_tags.push_targets
                                ? this.task.objects_by_tags.push_targets
                                : 0,
                            tag_ids: this.selectedResourceTags
                        }
                    };
                }
            }
        } else if (this.task.action === "set_cluster_size") {
            // Cluster Size
            this.valid = true;

            model = {
                name: this.task.name,
                start_offset_sec: this.startOffsetMins * 60,
                action: this.task.action,
                parameter: this.task.parameter,
                clusters: [this.selectedSizeCluster], //
                feeders: [],
                broadcasters: [], // Remove?
                receivers: [],
                sources: [],
                adaptive_channels: [],
                delivery_channels: [],
                publishing_targets: [],
                rtmp_targets: [],
                udp_rtp_targets: [],
                rist_targets: [],
                srt_targets: [],
                ndi_targets: [],
                pull_targets: [],
                push_targets: [],
                objects_by_tags: {
                    clusters: 0,
                    feeders: 0,
                    broadcasters: 0,
                    receivers: 0,
                    sources: 0,
                    mediaconnect_sources: 0,
                    mediaconnect_flows: 0,
                    adaptive_channels: 0,
                    delivery_channels: 0,
                    publishing_targets: 0,
                    rtmp_targets: 0,
                    udp_rtp_targets: 0,
                    rist_targets: 0,
                    srt_targets: 0,
                    ndi_targets: 0,
                    pull_targets: 0,
                    push_targets: 0,
                    tag_ids: []
                }
            };
        } else if (this.task.action === "set_cqa") {
            // CQA
            if (this.by === "objects") {
                if (!this.checkObjects()) {
                    this.objectErrorMessage = true;
                    this.valid = false;
                } else {
                    this.objectErrorMessage = false;
                    this.valid = true;
                    //
                    model = {
                        name: this.task.name,
                        start_offset_sec: this.startOffsetMins * 60,
                        action: this.task.action,
                        parameter: this.task.parameter,
                        clusters: [], //
                        feeders: [],
                        broadcasters: [], // Remove?
                        receivers: [],
                        sources: _.map(this.selectedSources, "id"),
                        mediaconnect_sources: [],
                        mediaconnect_flows: [],
                        adaptive_channels: [],
                        delivery_channels: [],
                        publishing_targets: [],
                        rtmp_targets: [],
                        udp_rtp_targets: [],
                        rist_targets: [],
                        srt_targets: [],
                        ndi_targets: [],
                        pull_targets: [],
                        push_targets: [],
                        objects_by_tags: {
                            clusters: 0,
                            feeders: 0,
                            broadcasters: 0,
                            receivers: 0,
                            sources: 0,
                            mediaconnect_sources: 0,
                            mediaconnect_flows: 0,
                            adaptive_channels: 0,
                            delivery_channels: 0,
                            publishing_targets: 0,
                            rtmp_targets: 0,
                            udp_rtp_targets: 0,
                            srt_targets: 0,
                            ndi_targets: 0,
                            pull_targets: 0,
                            push_targets: 0,
                            tag_ids: []
                        }
                    };
                }
            } else {
                this.valid = true;
                //
                model = {
                    name: this.task.name,
                    start_offset_sec: this.startOffsetMins * 60,
                    action: this.task.action,
                    parameter: this.task.parameter,
                    clusters: [],
                    feeders: [],
                    broadcasters: [], // Remove?
                    receivers: [],
                    sources: [],
                    adaptive_channels: [],
                    delivery_channels: [],
                    mediaconnect_sources: [],
                    mediaconnect_flows: [],
                    publishing_targets: [],
                    rtmp_targets: [],
                    udp_rtp_targets: [],
                    rist_targets: [],
                    srt_targets: [],
                    ndi_targets: [],
                    pull_targets: [],
                    push_targets: [],
                    objects_by_tags: {
                        clusters: 0,
                        feeders: 0,
                        broadcasters: 0,
                        receivers: 0,
                        sources: 1,
                        mediaconnect_sources: 0,
                        mediaconnect_flows: 0,
                        adaptive_channels: 0,
                        delivery_channels: 0,
                        publishing_targets: 0,
                        rtmp_targets: 0,
                        udp_rtp_targets: 0,
                        rist_targets: 0,
                        srt_targets: 0,
                        ndi_targets: 0,
                        pull_targets: 0,
                        push_targets: 0,
                        tag_ids: this.selectedResourceTags
                    }
                };
            }
        } else if (this.task.action === "assign_target_to_channel") {
            // Assign Targets to Channel
            if (this.by === "objects") {
                if (this.channelType === "adaptive") {
                    if (this.selectedAdaptiveTargets.length === 0) {
                        this.objectErrorMessage = true;
                        this.valid = false;
                    } else {
                        this.objectErrorMessage = false;
                        this.valid = true;
                        //
                        model = {
                            name: this.task.name,
                            start_offset_sec: this.startOffsetMins * 60,
                            action: this.task.action,
                            parameter: this.task.parameter,
                            clusters: [],
                            feeders: [],
                            broadcasters: [], // Remove?
                            receivers: [],
                            sources: [],
                            adaptive_channels: [this.selectedAdaptiveChannel],
                            delivery_channels: [],
                            mediaconnect_flows: [],
                            mediaconnect_sources: [],
                            publishing_targets: _.map(this.selectedAdaptiveTargets, "objId"),
                            rtmp_targets: [],
                            udp_rtp_targets: [],
                            rist_targets: [],
                            srt_targets: [],
                            ndi_targets: [],
                            pull_targets: [],
                            push_targets: [],
                            objects_by_tags: {
                                clusters: 0,
                                feeders: 0,
                                broadcasters: 0,
                                receivers: 0,
                                sources: 0,
                                mediaconnect_sources: 0,
                                mediaconnect_flows: 0,
                                adaptive_channels: 0,
                                delivery_channels: 0,
                                publishing_targets: 0,
                                rtmp_targets: 0,
                                udp_rtp_targets: 0,
                                srt_targets: 0,
                                ndi_targets: 0,
                                pull_targets: 0,
                                push_targets: 0,
                                tag_ids: []
                            }
                        };
                    }
                } else if (this.channelType === "delivery") {
                    if (this.selectedDeliveryTargets.length === 0) {
                        this.objectErrorMessage = true;
                        this.valid = false;
                    } else {
                        this.objectErrorMessage = false;
                        this.valid = true;

                        model = {
                            name: this.task.name,
                            start_offset_sec: this.startOffsetMins * 60,
                            action: this.task.action,
                            parameter: this.task.parameter,
                            clusters: [],
                            feeders: [],
                            broadcasters: [], // Remove?
                            receivers: [],
                            sources: [],
                            adaptive_channels: [],
                            delivery_channels: [this.selectedDeliveryChannel],
                            mediaconnect_flows: [],
                            mediaconnect_sources: [],
                            publishing_targets: [],
                            rtmp_targets: _.map(
                                this.selectedDeliveryTargets.filter(t => t.type === "rtmp"),
                                "objId"
                            ),
                            udp_rtp_targets: _.map(
                                this.selectedDeliveryTargets.filter(t => t.type === "udp_rtp"),
                                "objId"
                            ),
                            rist_targets: _.map(
                                this.selectedDeliveryTargets.filter(t => t.type === "rist"),
                                "objId"
                            ),
                            srt_targets: _.map(
                                this.selectedDeliveryTargets.filter(t => t.type === "srt"),
                                "objId"
                            ),
                            ndi_targets: _.map(
                                this.selectedDeliveryTargets.filter(t => t.type === "ndi"),
                                "objId"
                            ),
                            pull_targets: _.map(
                                this.selectedDeliveryTargets.filter(t => t.type === "pull"),
                                "objId"
                            ),
                            push_targets: _.map(
                                this.selectedDeliveryTargets.filter(
                                    t => t.type === "push" || t.type === "mediaconnect"
                                ),
                                "objId"
                            ),
                            objects_by_tags: {
                                clusters: 0,
                                feeders: 0,
                                broadcasters: 0,
                                receivers: 0,
                                sources: 0,
                                mediaconnect_sources: 0,
                                mediaconnect_flows: 0,
                                adaptive_channels: 0,
                                delivery_channels: 0,
                                publishing_targets: 0,
                                rtmp_targets: 0,
                                udp_rtp_targets: 0,
                                rist_targets: 0,
                                srt_targets: 0,
                                ndi_targets: 0,
                                pull_targets: 0,
                                push_targets: 0,
                                tag_ids: []
                            }
                        };
                    }
                } else if (this.channelType === "mediaconnect") {
                    if (this.selectedMediaConnectTargets.length === 0) {
                        this.objectErrorMessage = true;
                        this.valid = false;
                    } else {
                        this.objectErrorMessage = false;
                        this.valid = true;

                        model = {
                            name: this.task.name,
                            start_offset_sec: this.startOffsetMins * 60,
                            action: this.task.action,
                            parameter: this.task.parameter,
                            clusters: [],
                            feeders: [],
                            broadcasters: [], // Remove?
                            receivers: [],
                            sources: [],
                            adaptive_channels: [],
                            delivery_channels: [],
                            mediaconnect_flows: [this.selectedMediaConnectFlow],
                            mediaconnect_sources: [],
                            publishing_targets: [],
                            udp_rtp_targets: _.map(
                                this.selectedMediaConnectTargets.filter(t => t.type === "udp_rtp"),
                                "objId"
                            ),
                            rist_targets: _.map(
                                this.selectedMediaConnectTargets.filter(t => t.type === "rist"),
                                "objId"
                            ),
                            pull_targets: _.map(
                                this.selectedMediaConnectTargets.filter(t => t.type === "pull"),
                                "objId"
                            ),
                            push_targets: _.map(
                                this.selectedMediaConnectTargets.filter(t => t.type === "push"),
                                "objId"
                            ),
                            srt_targets: _.map(
                                this.selectedDeliveryTargets.filter(t => t.type === "srt"),
                                "objId"
                            ),
                            objects_by_tags: {
                                clusters: 0,
                                feeders: 0,
                                broadcasters: 0,
                                receivers: 0,
                                sources: 0,
                                mediaconnect_sources: 0,
                                mediaconnect_flows: 0,
                                adaptive_channels: 0,
                                delivery_channels: 0,
                                publishing_targets: 0,
                                rtmp_targets: 0,
                                udp_rtp_targets: 0,
                                rist_targets: 0,
                                srt_targets: 0,
                                ndi_targets: 0,
                                pull_targets: 0,
                                push_targets: 0,
                                tag_ids: []
                            }
                        };
                    }
                }
            } else {
                if (!this.checkTags()) {
                    this.typeErrorMessage = true;
                    this.valid = false;
                } else {
                    this.typeErrorMessage = false;
                    this.valid = true;
                    //
                    model = {
                        name: this.task.name,
                        start_offset_sec: this.startOffsetMins * 60,
                        action: this.task.action,
                        parameter: this.task.parameter,
                        clusters: [],
                        feeders: [],
                        broadcasters: [], // Remove?
                        receivers: [],
                        sources: [],
                        adaptive_channels: this.channelType === "adaptive" ? [this.selectedAdaptiveChannel] : [],
                        delivery_channels: this.channelType === "delivery" ? [this.selectedDeliveryChannel] : [],
                        mediaconnect_flows: this.channelType === "mediaconnect" ? [this.selectedMediaConnectFlow] : [],
                        mediaconnect_sources: [],
                        publishing_targets: [],
                        rtmp_targets: [],
                        udp_rtp_targets: [],
                        rist_targets: [],
                        srt_targets: [],
                        ndi_targets: [],
                        pull_targets: [],
                        push_targets: [],
                        objects_by_tags: {
                            clusters: 0,
                            feeders: 0,
                            broadcasters: 0,
                            receivers: 0,
                            sources: 0,
                            mediaconnect_sources: 0,
                            mediaconnect_flows: 0,
                            adaptive_channels: 0,
                            delivery_channels: 0,
                            publishing_targets:
                                this.channelType === "adaptive" ? this.task.objects_by_tags.publishing_targets : 0,
                            rtmp_targets: this.channelType === "delivery" ? this.task.objects_by_tags.rtmp_targets : 0,
                            udp_rtp_targets:
                                this.channelType === "delivery" || this.channelType === "mediaconnect"
                                    ? this.task.objects_by_tags.udp_rtp_targets
                                    : 0,
                            rist_targets:
                                this.channelType === "delivery" || this.channelType === "mediaconnect"
                                    ? this.task.objects_by_tags.rist_targets
                                    : 0,
                            srt_targets: this.channelType === "delivery" ? this.task.objects_by_tags.srt_targets : 0,
                            ndi_targets: this.channelType === "delivery" ? this.task.objects_by_tags.ndi_targets : 0,
                            pull_targets:
                                this.channelType === "delivery" || this.channelType === "mediaconnect"
                                    ? this.task.objects_by_tags.pull_targets
                                    : 0,
                            push_targets:
                                this.channelType === "delivery" || this.channelType === "mediaconnect"
                                    ? this.task.objects_by_tags.push_targets
                                    : 0,
                            tag_ids: this.selectedResourceTags
                        }
                    };
                }
            }
        }
        if (this.task.action === "remove_target_from_channel") {
            // Remove Targets from Channel
            if (this.by === "objects") {
                if (this.selectedTargets.length === 0) {
                    this.objectErrorMessage = true;
                    this.valid = false;
                } else {
                    this.objectErrorMessage = false;
                    this.valid = true;

                    model = {
                        name: this.task.name,
                        start_offset_sec: this.startOffsetMins * 60,
                        action: this.task.action,
                        parameter: this.task.parameter,
                        clusters: [],
                        feeders: [],
                        broadcasters: [], // Remove?
                        receivers: [],
                        sources: [],
                        adaptive_channels: [],
                        delivery_channels: [],
                        mediaconnect_sources: [],
                        mediaconnect_flows: [],
                        publishing_targets: _.map(
                            this.selectedTargets.filter(t => t.adaptive === true),
                            "objId"
                        ),
                        rtmp_targets: _.map(
                            this.selectedTargets.filter(t => t.type === "rtmp"),
                            "objId"
                        ),
                        udp_rtp_targets: _.map(
                            this.selectedTargets.filter(t => t.type === "udp_rtp"),
                            "objId"
                        ),
                        rist_targets: _.map(
                            this.selectedTargets.filter(t => t.type === "rist"),
                            "objId"
                        ),
                        srt_targets: _.map(
                            this.selectedTargets.filter(t => t.type === "srt"),
                            "objId"
                        ),
                        ndi_targets: _.map(
                            this.selectedTargets.filter(t => t.type === "ndi"),
                            "objId"
                        ),
                        pull_targets: _.map(
                            this.selectedTargets.filter(t => t.type === "pull"),
                            "objId"
                        ),
                        push_targets: _.map(
                            this.selectedTargets.filter(t => t.type === "push" || t.type === "mediaconnect"),
                            "objId"
                        ),
                        objects_by_tags: {
                            clusters: 0,
                            feeders: 0,
                            broadcasters: 0,
                            receivers: 0,
                            sources: 0,
                            mediaconnect_sources: 0,
                            mediaconnect_flows: 0,
                            adaptive_channels: 0,
                            delivery_channels: 0,
                            publishing_targets: 0,
                            rtmp_targets: 0,
                            udp_rtp_targets: 0,
                            rist_targets: 0,
                            srt_targets: 0,
                            ndi_targets: 0,
                            pull_targets: 0,
                            push_targets: 0,

                            tag_ids: []
                        }
                    };
                }
            } else {
                if (!this.checkTags()) {
                    this.typeErrorMessage = true;
                    this.valid = false;
                } else {
                    this.typeErrorMessage = false;
                    this.valid = true;

                    model = {
                        name: this.task.name,
                        start_offset_sec: this.startOffsetMins * 60,
                        action: this.task.action,
                        parameter: this.task.parameter,
                        clusters: [],
                        feeders: [],
                        broadcasters: [], // Remove?
                        receivers: [],
                        sources: [],
                        adaptive_channels: [],
                        delivery_channels: [],
                        mediaconnect_sources: [],
                        mediaconnect_flows: [],
                        publishing_targets: [],
                        rtmp_targets: [],
                        udp_rtp_targets: [],
                        rist_targets: [],
                        srt_targets: [],
                        ndi_targets: [],
                        pull_targets: [],
                        push_targets: [],
                        objects_by_tags: {
                            clusters: 0,
                            feeders: 0,
                            broadcasters: 0,
                            receivers: 0,
                            sources: 0,
                            mediaconnect_sources: 0,
                            mediaconnect_flows: 0,
                            adaptive_channels: 0,
                            delivery_channels: 0,
                            publishing_targets: this.task.objects_by_tags.publishing_targets
                                ? this.task.objects_by_tags.publishing_targets
                                : 0,
                            rtmp_targets: this.task.objects_by_tags.rtmp_targets
                                ? this.task.objects_by_tags.rtmp_targets
                                : 0,
                            udp_rtp_targets: this.task.objects_by_tags.udp_rtp_targets
                                ? this.task.objects_by_tags.udp_rtp_targets
                                : 0,
                            rist_targets: this.task.objects_by_tags.rist_targets
                                ? this.task.objects_by_tags.rist_targets
                                : 0,
                            srt_targets: this.task.objects_by_tags.srt_targets
                                ? this.task.objects_by_tags.srt_targets
                                : 0,
                            ndi_targets: this.task.objects_by_tags.ndi_targets
                                ? this.task.objects_by_tags.ndi_targets
                                : 0,
                            pull_targets: this.task.objects_by_tags.pull_targets
                                ? this.task.objects_by_tags.pull_targets
                                : 0,
                            push_targets: this.task.objects_by_tags.push_targets
                                ? this.task.objects_by_tags.push_targets
                                : 0,
                            tag_ids: this.selectedResourceTags
                        }
                    };
                }
            }
        }

        if (this.isEdit && this.valid && model) {
            const result = await this.as.updateTask(this.task, model);
            if (result) {
                this.saving = false;
                this.router.navigate([Constants.urls.automation, this.taskSet.name]);
            } else this.saving = false;
        } else if (this.valid && model) {
            const result = await this.as.addTask(this.taskSet, model);
            if (result) {
                this.saving = false;
                this.router.navigate([Constants.urls.automation, this.taskSet.name]);
            } else this.saving = false;
        } else {
            this.saving = false;
        }
    }

    cancel() {
        if (this.isEdit || this.isClone) this.router.navigate([Constants.urls.automation, this.taskSetName]);
        else this.router.navigate([Constants.urls.automation, this.taskSetName]);
    }
}
